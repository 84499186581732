import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/Input";
import "./ManagerCondominium.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationService, OpenAPI, UserService } from "kauai-super-app-client-module/client-web";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, ConfigProvider, List, Skeleton, Space, Typography } from "antd";
import Navbar from "../../components/navbar/navbar";
import { get_User } from "../../services/user";

export default function ManagerCondominium() {
    const [load, setLoad] = useState(true);
    const [users, setUsers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        console.log("View" + ManagerCondominium.name + " OPENED");
        get_User().then((response) => {
            const ifContainsKey = response.data.filter((item) => Object.keys(item).includes("condominium"));
            const verifiedCondominiumNotNull = ifContainsKey.filter((item) => item.condominium != null);
            var filterResult = verifiedCondominiumNotNull.filter((item) => item.condominium.verified === false);
            setUsers(filterResult);
            setLoad(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Error al cargar los datos");
            setLoad(false);
        });
        loading();
        return () => {
            console.log("View" + ManagerCondominium.name + " CLOSED");
        };
    }, []);
    async function loading() {
        console.log("Loading...");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        console.log("Loaded!");
        setLoad(false);
    }

    function reflashData() {
        setLoad(true);
            get_User().then((response) => {
            var ifContainsKey = response.data.filter((item) => Object.keys(item).includes("condominium"));
            var filterResult = ifContainsKey.filter((item) => item.condominium.verified == false);
            setUsers(filterResult);
            setLoad(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Error al cargar los datos");
            setLoad(false);
        });
    }

    function AcceptUser(params) {
        console.log("ACCEPT");
        console.log(params);
        NotificationService.serviceNotificationPost({
            exponentPushToken: params.exponent_push_token,
            email_user: params.email,
            message: "Sua solicitação de condômino foi aceita, agora você pode acessar todas as funcionalidades da App.",
            title: "Solicitação de condômino",
            type: "info",
            application_destination: "all",
            data: {
                value: "",
            },
        }).then((responseNotification) => {
            console.log(responseNotification);
        }).catch((error) => {
            console.error(error);
        });
        UserService.updateAccountCondominium({
            id_user: params._id.$oid,
            block: params.condominium.block,
            patch: params.condominium.patch,
            id_condominium: params.condominium.id_condominium,
            name_condominium: params.condominium.condominium_name,
            verified: true,
        }).then((response) => {
            console.log(response);
            toast.success("Usuario aceptado");
            setUsers([])
            reflashData();
        }).catch((error) => {
            console.log(error);
            toast.error("Error al aceptar usuario");
        });
    }

    function RejectUser(params) {
        console.log("REJECT");
        console.log(params);
        console.log({
            exponentPushToken: params.exponent_push_token,
            email_user: params.email,
            message: "Sua solicitação de condômino foi rejeitada, entre em contato com o administrador do condomínio para mais informações.",
            title: "Solicitação de condômino",
            type: "info",
            application_destination: "all",
            data: {
                value: "",
            },
        });

        NotificationService.serviceNotificationPost({
            exponentPushToken: params.exponent_push_token,
            email_user: params.email,
            message: "Sua solicitação de condômino foi rejeitada, entre em contato com o administrador do condomínio para mais informações.",
            title: "Solicitação de condômino",
            type: "info",
            application_destination: "all",
            data: {
                value: "",
            },
        }).then((responseNotification) => {
            console.log(responseNotification);
        }).catch((error) => {
            console.error(error);
        });
        UserService.deleteAccountCondominium(params._id.$oid).then((response) => {
            console.log(response);
            toast.success("Solocitação de condômino rejeitada");
            setUsers([])
            reflashData();
        }).catch((error) => {
            console.log(error);
            toast.error("Error al rechazar usuario");
        });
    }

    return load ? (
        <Loader />
    ) : (
        <div className="home_container">
            <Navbar />
            <div className="body_main">
                {/* <LoginTab /> */}
                <div className="container_home">
                    <List
                        header={<Typography.Title level={3}>Solicitação de Condômino</Typography.Title>}
                        className="demo-loadmore-list"
                        // loading={users.length === 0}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={users}
                        renderItem={(item) => (
                            <List.Item
                                actions={
                                    [
                                        <Button type="default" danger key="list-loadmore-edit" onClick={() => RejectUser(item)}>Reprovar</Button>,
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Button: {
                                                        colorPrimary: "green",
                                                        colorPrimaryHover: "green",
                                                        colorPrimaryActive: "green",
                                                    },
                                                },
                                            }}>
                                            <Button type="primary" key="list-loadmore-more" onClick={() => AcceptUser(item)}>Aceitar</Button>
                                        </ConfigProvider>
                                    ]
                                }
                            >
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        avatar={<Avatar size={70} src={item?.picture} />}
                                        title={<span>{item?.name}</span>}
                                        description={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span>{"Email: " + item?.email}</span>
                                                <span>{"Contato: " + item?.phone}</span>
                                                <span>{"Condôminio: " + item?.condominium?.condominium_name}</span>
                                                <span>{"Quadra: " + item?.condominium?.block}</span>
                                                <span>{"Lote: " + item?.condominium?.patch}</span>
                                            </div>
                                        }
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

import "./productDetailsComponent.css";
import { useEffect, useState } from "react";
import { Switch, ConfigProvider } from "antd";
import { ProductsService } from "kauai-super-app-client-module/client-web";
interface DetailsProps {
  id: React.ReactNode;
}
export default function ProductDetailsComponent<DetailsProps>({ id }: any) {
  interface Products {
    _id: {
      $oid: string;
    };
    name: string;
    description: string;
    price: number;
    price_tag: string;
    food_region: string;
    size: number;
    promotion: string;
    special_offers: [];
    category: string;
    is_enable: boolean;
    quantity: number;
    is_visible: boolean;
    complements: {
      max: number;
      items: [];
    };
    picture: string;
    optional_complements: [];
    code: string;
  }
  const [products, setProducts] = useState<Products | any>({});
  const [disabled, setDisabled] = useState<boolean>(true);
  useEffect(() => {
    ProductsService.getProductsById(id)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div
        className={
          disabled ? "product_details_box" : "product_details_box disabled"
        }
      >
        <div className="product_details_img">
          <img src={products.picture} alt="" />
        </div>
        <div className="product_details_info_box">
          <div className="product_details_primary_info_box">
            <div>
              <div className="product_details_name_box">
                <p>{products.name}</p>
              </div>
              <div className="product_details_description_box">
                <p>{products.description}</p>
              </div>
            </div>
            <div className="product_details_price_box">
              <div>{products.price}</div>
              <div>{products.price_tag}</div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="product_details_category_box">
            <p className="product_details_name_box">Categoria:</p>
            <p>{products.category}</p>
          </div>
          <div className="product_details_code_box">
            <p className="product_details_name_box">Código:</p>
            <p>{products.code}</p>
          </div>
        </div>
      </div>
    </>
  );
}

import http from "../config/axios";


export async function get_All_Deliveryman() {
    const url = `/deliveryman/get-all-deliveryman/`;
    try {
        const response = await http
            .request({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                url,
            });
        return response.data;
    } catch (error) {
        console.error("Error fetching deliverymans:", error);
        throw error;
    }
}
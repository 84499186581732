import http from "../config/axios";

interface updateProductsInRestaurantProps {
    restaurantId: string;
    productId: string;
    requestBody: Object;
}
interface updateOrderInProductsProps {
    restaurantId: string;
    order_products: Object;
}


export async function updateProductsInRestaurant({ restaurantId, productId, requestBody }: updateProductsInRestaurantProps) {
    const url = `/restaurants/product/${productId}/${restaurantId}`;

    try {
        const response = await http.put(url, requestBody, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error: any) {
        console.error('Error making the request:', error);
        throw new Error(`Update product failed: ${error.message || 'Some error occurred'}`);
    }
}

export async function updateOrderInProducts({ restaurantId, order_products }: updateOrderInProductsProps) {
    const url = `/restaurants/order_products/${restaurantId}`;

    const dataBody = {
        order_products: order_products
    }
    try {
        const response = await http.put(url, dataBody, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    }
    catch (error: any) {
        console.error('Error making the request:', error);
        throw new Error(`Update product failed: ${error.message || 'Some error occurred'}`);
    }
}

export async function update_List_Of_Products({ restaurantId, requestBody }: { restaurantId: string, requestBody: Array<Object> }) {
    const url = `/restaurants/product-list-to-update/${restaurantId}`;

    try {
        const response = await http.put(url, requestBody, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error: any) {
        console.error('Error making the request:', error);
        throw new Error(`Update product failed: ${error.message || 'Some error occurred'}`);
    }
}
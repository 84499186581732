import "./VendingMachine.css";
import { FreezerService } from "kauai-super-app-client-module/client-web";
import { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import { Link } from "react-router-dom";

export default function VendingMachine() {
  const [allFreezers, setAllFreezers] = useState<any[]>([]);
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const defaultImageUrl =
    "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg";

  useEffect(() => {
    FreezerService.getAllFreezer()
      .then((response) => {
        setAllFreezers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    var interval = setInterval(() => {
      FreezerService.getAllFreezer()
        .then((response) => {
          setAllFreezers(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("allFreezers", allFreezers);
    const updatedImages = allFreezers.map((item) => ({
      image1:
        item.cam_one !== ""
          ? `data:image/png;base64,${item.cam_one}`
          : defaultImageUrl,
      image2:
        item.cam_two !== ""
          ? `data:image/png;base64,${item.cam_two}`
          : defaultImageUrl,
      image3:
        item.cam_three !== ""
          ? `data:image/png;base64,${item.cam_three}`
          : defaultImageUrl,
      image4:
        item.cam_four !== ""
          ? `data:image/png;base64,${item.cam_four}`
          : defaultImageUrl,
    }));
    setImageUrls(updatedImages);
    return () => { };
  }, [allFreezers]);

  useEffect(() => { }, []);
  return (
    <>
      <div className="body_main">
        <div className="container_machine">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignContent: "center",
              textAlign: "center",
              height: "100%",
            }}
          >
            {allFreezers.map((item, indexF) => (
              <div className="freezer">
                <div style={{ width: "20vw" }} key={indexF}>
                  {item._id.$oid}
                </div>

                {imageUrls.map((images, index) => {
                  if (index == indexF) {
                    return (
                      <div key={index} className="container_images">
                        <img
                          src={images.image1}
                          className="cam"
                          alt={`cam 1 of item ${index}`}
                        />
                        <img
                          src={images.image2}
                          className="cam"
                          alt={`cam 2 of item ${index}`}
                        />
                        <img
                          src={images.image3}
                          className="cam"
                          alt={`cam 3 of item ${index}`}
                        />
                        <img
                          src={images.image4}
                          className="cam"
                          alt={`cam 4 of item ${index}`}
                        />
                      </div>
                    );
                  }
                })}

                <Link to={`/vendingmachine/edit/${item._id.$oid}`}>
                  <Button
                    text="Detalhes"
                    style={{ width: "5vw", border: 0, borderRadius: "30px" }}
                    onClick={() => { }}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

import {
  GoogleMap,
  LoadScript,
  GoogleMapProps,
  MarkerF,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

export default function MarkerRestaurant({
    markers
}) {
  return (
    <>
      {markers.map((value) => {
        return (
          <div key={String(value.id)}>
            <Marker
              key={String(value.id)}
              position={{ lat: value.position.lat, lng: value.position.lng }}
              options={{
                optimized: true,
                visible: true,
                label: {
                  text:
                    value?.name === undefined
                      ? "Não identificado"
                      : value?.name,
                  className: "marker-label",
                },
              }}
              icon={{
                url: "https://bohocasasstorage.blob.core.windows.net/boho-casas/pin_restaurant.png",
                scaledSize: new window.google.maps.Size(70, 80),
              }}
            />
          </div>
        );
      })}
    </>
  );
}

import http from "../config/axios";

interface OpeningHours {
  early_morning?: string;
  end_morning?: string;
  early_afternoon?: string;
  end_afternoon?: string;
  nights_start?: string;
  nights_end?: string;
}

export async function restaurantsAll() {
  try {
    const response = await http.get("/restaurants");
    return response.data;
  } catch (error) {
    console.error("Error fetching restaurants:", error);
    throw error;
  }
}

export async function getRestaurantById({ restaurantId }: { restaurantId: string }) {
  const url = `/restaurants/products/${restaurantId}`;
  try {
    const response = await http
      .request({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        url,
      });

    return response.data;
  } catch (error) {
    console.error("aaaquiiiii-->>>:", error);
    throw error;
  }
}

export async function addProductRestaurant({ restaurantId, product }: { restaurantId: string, product: any }) {
  const url = `/restaurants/product/${restaurantId}`;
  try {
    const response = await http
      .request({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url,
        data: product,
      });

    return response.data;
  }
  catch (error: any) {
    console.error('Error making the request:', error);
    throw new Error(`Add product failed: ${error.message || 'Some error occurred'}`);
  }

}

export async function update_Restaurant_Working(restaurantId: string, isWorking: boolean): Promise<any> {
  const url = `/restaurants/restaurant-working/${restaurantId}?is_working=${isWorking}`;

  const dataBody = {
    isWorking: isWorking
  };

  try {
    const response = await http.put(url, dataBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error: any) {
    console.error('Error making the request:', error);
    throw new Error(`Update working failed: ${error.message || 'Some error occurred'}`);
  }
}


export async function update_Opening_Hour(restaurantId: string, day: string, requestBody: OpeningHours): Promise<any> {
  const url = `/restaurants/opening_hour/${restaurantId}?day=${day}`;
  const dataBody = {
      opening_hour: requestBody
  };

  try {
      const response = await http.put(url, dataBody, {
          headers: {
              "Content-Type": "application/json",
          },
      });

      console.log('response.data-->', response.data)
      return response.data;
  } catch (error: any) {
      console.error('Erro ao fazer a requisição:', error);
      throw new Error(`Update hours failed: ${error.message || 'Some error occurred'}`);
  }
}




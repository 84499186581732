import "./toBeNamedCard4.css";
export default function ToBeNamedCard4() {
  return (
    <div className="card_tobenamed4">
      <h4>Sessions:</h4>
      <div className="card_info2">
        <p>teste</p>
      </div>
    </div>
  );
}

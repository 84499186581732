import { createSlice } from '@reduxjs/toolkit'

export const restaurantFeatures = createSlice({
    name: 'restaurant',
    initialState: {},
    reducers: {
        saveRestaurant: (state, action) => {
            console.log("RESTAURANT REDUX PAYLOAD", action.payload);
            state = action.payload
            return state
        },
    },
})

export const { saveRestaurant } = restaurantFeatures.actions

export default restaurantFeatures.reducer
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DeliverymanService } from "kauai-super-app-client-module/client-web";
import "../DeliveryManDetails/deliverymandetails.css";
export default function DeliveryManDetails() {
  const { cpf } = useParams<{ cpf: string | any }>();
  interface HomeAddressModel {
    street: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    Latitude?: string;
    Longitude?: string;
  }
  interface MotorcycleInformationModel {
    year: string;
    brand: string;
    model: string;
    color: string;
    registration: string;
  }
  interface DeliverymanLocationPatch {
    latitude: string;
    longitude: string;
  }
  interface DeliveryMan {
    name: string;
    email: string;
    password?: string;
    picture?: string;
    cpf?: string;
    phone?: string;
    birth_date: string;
    /**
     * 0 = not verified, 1 = verified, 2 = rejected
     */
    is_verified?: number;
    is_online?: boolean;
    document_photo_front?: string;
    document_photo_back?: string;
    motorcycle_document_photo_front?: string;
    motorcycle_document_photo_back?: string;
    motorcycle_information: MotorcycleInformationModel;
    home_address: HomeAddressModel;
    current_location?: DeliverymanLocationPatch;
  }

  const [deliveryMan, setDeliveryMan] = useState<DeliveryMan[] | any>([]);

  useEffect(() => {
    DeliverymanService.getDeliverymanByCpf(cpf)
      .then((response) => {
        console.log(response.data);
        setDeliveryMan(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="body_main">
        <div className="container_details">
          <div className="box_deliveryman_details">
            <div className="deliveryman_img">
              <img
                src={"data:image/png;base64," + deliveryMan.picture}
                alt=""
              />
            </div>
            <div className="box_info_title">
              <h4>Informação do Motorista:</h4>
            </div>
            <div className="box_deliveryman_info">
              <text>
                <span>Nome:</span> {deliveryMan.name}
              </text>
              <text>
                <span>Email:</span> {deliveryMan.email}
              </text>
              <text>
                <span>CPF:</span> {deliveryMan.phone}
              </text>
              <text>
                <span>Data de Nascimento:</span> {deliveryMan.birth_date}
              </text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

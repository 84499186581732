import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import { Button, Form, Input, Modal, Space, Table, notification } from "antd";
import Column from "antd/es/table/Column";
import { get_Delivery_Address, add_Delivery_Address, update_Delivery_Address, delete_Delivery_Address } from "../../services/delivery";

export default function DeliveryAddressManager() {
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [load, setLoad] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
    const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
    const [value, setValue] = useState(0);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const idDeliveryAddressToDelete = useRef(null);

    useEffect(() => {
        getDeliveryAddress();
    }, []);

    const openNotificationWithIcon = (type, title, msg) => {
        api[type]({
            message: title,
            description: msg,
        });
    };

    function getDeliveryAddress() {
        get_Delivery_Address().then((response) => {
            setDeliveryAddress(response.data);
            setLoad(false);
        }).catch((error) => {
            console.error(error);
            setLoad(false);
        });
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setLoad(true);
        delete_Delivery_Address({ addressId: idDeliveryAddressToDelete.current }).then((response) => {
            getDeliveryAddress();
            setLoad(true);
        }).catch((error) => {
            console.error(error);
            setLoad(false);
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOkEdit = () => {
        setIsModalVisibleEdit(false);
        setLoad(true);

        const data = deliveryAddress.find((item) => item._id.$oid === idDeliveryAddressToDelete.current);

        if (data) {
            data.value = parseFloat(value);
            update_Delivery_Address({ addressId: idDeliveryAddressToDelete.current, requestBody: data })
                .then((response) => {
                    getDeliveryAddress();
                    setLoad(false);
                    openNotificationWithIcon('success', "Sucesso", "Taxa de entrega atualizado com sucesso.");
                }).catch((error) => {
                    openNotificationWithIcon('error', "Erro ao atualizar taxa de entrega. Por favor, tente novamente.");
                    console.error(error);
                    setLoad(false);
                });
        } else {
            console.error('Endereço não encontrado.');
            setLoad(false);
        }
    };


    const handleCancelEdit = () => {
        setIsModalVisibleEdit(false);
    };

    const handleOkCreate = () => {
        form.validateFields().then(values => {
            add_Delivery_Address({ requestBody: values }).then((response) => {
                setIsModalVisibleCreate(false);
                getDeliveryAddress();
                console.log(response);
                openNotificationWithIcon('success', "Sucesso", "Endereço de entrega criado com sucesso.");
            }).catch((error) => {
                openNotificationWithIcon('error', "Erro ao criar endereço de entrega. Por favor, tente novamente.");
                console.error(error);
            });
        }).catch((validationErrors) => {
            console.error("Erros de validação:", validationErrors);
        });
    };



    const handleCancelCreate = () => {
        setIsModalVisibleCreate(false);
        form.resetFields();
    }

    function filtersCity() {
        //group by city and return array of city
        const city = deliveryAddress.map((item) => item.city);
        const uniqueCity = [...new Set(city)];
        const filters = uniqueCity.map((item) => {
            return { text: item, value: item };
        });
        return filters;
    }

    function finishCreate(values) {
        console.log(values);
    }

    function finishCreateFailed(errorInfo) {
        console.log(errorInfo);
    }

    return (
        <>
            {contextHolder}
            <div className="home_container">
                <Navbar />
                <div className="body_main">
                    <Button type="primary" style={{ width: 200, marginLeft: 10 }} onClick={() => setIsModalVisibleCreate(true)}>Novo</Button>
                    <Table
                        loading={load}
                        dataSource={deliveryAddress}
                        style={{ marginLeft: 10 }}
                    >
                        <Column
                            title="Cidade"
                            dataIndex="city"
                            key="city"
                            filters={filtersCity()}
                            onFilter={(value, record) => record.city.indexOf(value) === 0}
                            sorter={(a, b) => a.city.length - b.city.length}
                            sortDirections={["descend", "ascend"]}
                            showSorterTooltip={false}
                        />
                        <Column
                            title="Bairro"
                            dataIndex="neighborhood"
                            key="neighborhood"
                            sorter={(a, b) => a.neighborhood.length - b.neighborhood.length}
                            sortDirections={["descend", "ascend"]}
                            showSorterTooltip={false}
                        />
                        <Column
                            title="Taxa"
                            dataIndex="value"
                            key="value"
                            sorter={(a, b) => a.value - b.value}
                            sortDirections={["descend", "ascend"]}
                            showSorterTooltip={false}
                        />
                        <Column
                            title="Ação"
                            dataIndex="action"
                            key="action"
                            render={(_, record) => (
                                <Space size="middle">
                                    <a onClick={() => {
                                        idDeliveryAddressToDelete.current = record._id.$oid;
                                        setIsModalVisible(true);
                                    }}>Excluir</a>
                                    <a onClick={() => {
                                        idDeliveryAddressToDelete.current = record._id.$oid;
                                        setValue(record.value);
                                        setIsModalVisibleEdit(true);
                                    }}>Editar</a>
                                </Space>
                            )} />
                    </Table>
                </div>
                <Modal title="Excluir" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelText="Cancelar" okText="Excluir">
                    <p>Deseja realmente excluir esse endereço? essa ação não pode ser desfeita.</p>
                </Modal>
                <Modal title="Atualizar" open={isModalVisibleEdit} onOk={handleOkEdit} onCancel={handleCancelEdit} cancelText="Cancelar" okText="Atualizar">
                    <p>Edite a taxa de entrega desse endereço</p>
                    <Input
                        type="number"
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        value={value} />
                </Modal>
                <Modal title="Criar" open={isModalVisibleCreate} onCancel={handleCancelCreate} cancelText="Cancelar" okButtonProps={{ hidden: true }}>
                    <Form
                        form={form}
                        initialValues={{ remember: false }}
                        onFinish={finishCreate}
                        onFinishFailed={finishCreateFailed}>
                        <Form.Item
                            label="Cidade"
                            name="city"
                            rules={[{ required: true, message: "Insira a cidade" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Bairro"
                            name="neighborhood"
                            rules={[{ required: true, message: "Insira o bairro" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Taxa"
                            name="value"
                            rules={[{ required: true, message: "Insira o valor da taxa" }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item style={{ justifyContent: "center", display: "flex" }}>
                            <Button onClick={handleOkCreate} style={{ width: 200 }} type="primary" htmlType="submit">Criar</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    );
}
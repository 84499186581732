import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home2/Home";
import Delivery from "./pages/Delivery/delivery";
import Navbar from "./components/navbar/navbar";
import DeliveryManDetails from "./pages/DeliveryManDetails/deliverymandetails";
import VendingMachine from "./pages/VendingMachine/VendingMachine";
import RestaurantPage from "./pages/Restaurant/RestaurantPage";
import CoordsProvider from "./context/coordsContext";
import ShowBoxRestaurantProvider from "./context/boxRestaurantContext";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import NotificationPage from "./pages/NotificationSenderPage/notificationPage";
import VendingMachineEditPage from "./pages/VendingMachineEdit/vendingMachineEditPage";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import ManagerCondominium from "./pages/ManagerCondominium/ManagerCondominium";
import Login from "./pages/Login/Login";
import RestaurantsInformation from "./pages/Restaurant/RestaurantsInformation";
import Logs from "./pages/Logs/Logs";
import OrderList from "./pages/OrderList/OrderList";
import DeliveryAddressManager from "./pages/DeliveryAddressManager/DeliveryAddressManager";

function App() {
  return (
    <Provider store={store}>
      <ShowBoxRestaurantProvider>
        <CoordsProvider>
          <Navbar />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProtectedRoute redirectPath="/auth/login" element={<Home />} />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route
                path="/auth/login"
                element={
                  <Login />
                }
              />

              <Route
                path="home"
                element={
                  <ProtectedRoute redirectPath="/auth/login" element={<Home />} />
                }
              />
              <Route
                path="delivery"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<Delivery />}
                  />
                }
              />
              <Route
                path="delivery/:cpf"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<DeliveryManDetails />}
                  />
                }
              />
              <Route
                path="vendingmachine"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<VendingMachine />}
                  />
                }
              />
              <Route
                path="vendingmachine/edit/:id"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<VendingMachineEditPage />}
                  />
                }
              />
              <Route
                path="restaurant"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<RestaurantPage />}
                  />
                }
              />
              <Route
                path="product/:id"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<ProductDetails />}
                  />
                }
              />
              <Route
                path="notify"
                element={
                  <ProtectedRoute
                    redirectPath="/auth/login"
                    element={<NotificationPage />}
                  />
                }
              />
              <Route
                path="restaurant-information"
                element={
                  <ProtectedRoute
                    redirectPath="/restaurant-information"
                    element={<RestaurantsInformation />}
                  />
                }
              />
              <Route
                path="order-list"
                element={
                  <ProtectedRoute
                    redirectPath="/order-list"
                    element={<OrderList />}
                  />
                }
              />
              <Route
                path="restaurant-logs"
                element={
                  <ProtectedRoute
                    redirectPath="/restaurant-logs"
                    element={<Logs />}
                  />
                }
              />
              <Route
                path="delivery-address"
                element={
                  <ProtectedRoute
                    redirectPath="/delivery-address"
                    element={<DeliveryAddressManager />}
                  />
                }
              />
              <Route path="/manager-condominium" element={<ManagerCondominium />} />
            </Routes>
          </BrowserRouter>
        </CoordsProvider>
      </ShowBoxRestaurantProvider>
    </Provider>
  );
}

export default App;

import React, { createContext, useContext, useState } from "react";

const CoordsContext = createContext()

export const useCoordsContext = () => useContext(CoordsContext)

export default function CoordsProvider({children}){
    const [coords, setCoords] = useState(null)

    const addCoords = (coordenates) =>{
        const newItem = {
            lat: parseFloat(coordenates.latitude),
            lng: parseFloat(coordenates.longitude)
        }
        setCoords(newItem)
        
    }

    const contextCoordsValue = {
        coords,
        addCoords,
    }

    return (
        <CoordsContext.Provider value={contextCoordsValue}>
            {children}
        </CoordsContext.Provider>
    )
}
import "./ProductDetails.css";
import { useParams } from "react-router-dom";
import ProductDetailsComponent from "../../components/productDetailsComponent/productDetailsComponent";


export default function ProductDetails() {
  const { id } = useParams<{ id: string | any }>();

  return (
    <>
      <div className="body_main">
        <div className="product_details_container">
          <ProductDetailsComponent id={id} />
        </div>
      </div>
    </>
  );
}

import http from "../config/axios";

export async function get_All_Orders_Active_Of_Restaurant({ restaurantId }: { restaurantId: string }) {
    const url = `/order/all-orders-active-of-restaurant/${restaurantId}`;
    try {
        const response = await http
            .request({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                url,
            });
        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error;
    }
}

export async function get_All_Delivered_Orders () {
    const url = `/order/all-delivered-orders`;
    try {
        const response = await http
            .request({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                url,
            });
        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error;
    }
}
import http from "../config/axios";
import qs from "qs";

async function loginUser({ email, password }: { email: string, password: string }) {
    console.log('email', email)
    console.log('password', password)
    const formData = qs.stringify({
        username: email,
        password: password
    });
    console.log('formData', formData)

    try {
        const res = await http.request({
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: formData,
            url: "/auth/login",
        });

        localStorage.setItem("token", res.data["access_token"]);

        return {
            auth_token: res.data["access_token"],
            refresh_token: res.data["refresh_token"],
        };
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
}

export {
    loginUser
}



import "./toBeNamedCard2.css";

export default function ToBeNamedCard2() {
  return (
    <div className="card_tobenamed2">
      <h4>Sessions:</h4>
      <div className="card_info2">
        <p>teste</p>
      </div>
    </div>
  );
}

import React, { createContext, useContext, useState } from "react";

const ShowBoxContext = createContext();

export const useShowBoxContext = () => useContext(ShowBoxContext);

export default function ShowBoxRestaurantProvider({ children }) {
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const showCreateProductBox = () => {
    setBoxVisible(!isBoxVisible);
  };

  const showEditProduct = () => {
    setIsEdit(!isEdit);
  };

  const contextBoxValues = {
    isBoxVisible,
    showCreateProductBox,
    isEdit,
    showEditProduct,
  };

  return (
    <ShowBoxContext.Provider value={contextBoxValues}>
      {children}
    </ShowBoxContext.Provider>
  );
}

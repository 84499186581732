import React, { useState, useEffect, useRef } from "react";
import "./OrderList.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import ListOrderComponent from "../../components/listOrder/listOrder"
import { Segmented } from "antd";
import DeliveryManFromOrderList from "../../components/deliveryManFromOrderList/deliveryManFromOrderList";
import secureLocalStorage from "react-secure-storage";
import { get_All_Orders_Active_Of_Restaurant, get_All_Delivered_Orders } from "../../services/order";
import { get_All_Deliveryman } from "../../services/deliveryman";
import { get_User } from "../../services/user";

export default function OrderList() {
    const [load, setLoad] = useState(true);
    const [users, setUsers] = useState([]);
    const [deliveryMan, setDeliveryMan] = useState([])
    const [activeOrders, setActiveOrders] = useState([])
    
    const selectedSegmented = useRef("Pedidos ativos")
    const restaurant = secureLocalStorage.getItem("restaurant");

    const navigate = useNavigate();

    useEffect(() => {
        console.log("View" + OrderList.name + " OPENED");

        get_User().then(response => {
            console.log(response.data)
            setUsers(response.data)
        }).catch(error => {
            console.error(error)
        })

        get_All_Deliveryman().then(response => {
            console.log(response.data)
            setDeliveryMan(response.data)
        }).catch(error => {
            console.error(error)
        })
        GetAllOrders()
        loading();
        return () => {
            console.log("View" + OrderList.name + " CLOSED");
        };
    }, []);

    useEffect(() => {
        if(selectedSegmented.current === "Pedidos ativos"){
        setInterval(() => {
            GetAllOrders()
        }, 3000); 
    }
    },[selectedSegmented])

    async function loading() {
        console.log("Loading...");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        console.log("Loaded!");
        setLoad(false);
    }

    function reflashPage() {
        GetAllOrders()
    }

    function GetAllOrders() {
        if (selectedSegmented.current == "Pedidos ativos") {
            get_All_Orders_Active_Of_Restaurant({restaurantId: restaurant})
            .then(response => {
                var orderListAsc = response.data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                orderListAsc = orderListAsc.filter(f => f.restaurant.id_restaurant === restaurant)
                setActiveOrders(orderListAsc)
            })
            .catch(error => {
                console.error(error)
            })
        } else {
            get_All_Delivered_Orders().then(response => {
                
                var orderListAsc = response.data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                orderListAsc = orderListAsc.filter(f => f.restaurant.id_restaurant == restaurant)
                setActiveOrders(orderListAsc)
            }).catch(error => {
                console.error(error)
            })
        }
    }

    function paymentMethod(payment) {
        switch (payment) {
            case "local-credit":
                return "Crédito no local";
            case "local_credit":
                return "Crédito no local";
            case "credit":
                return "Cartão de crédito pela App";
            case "credit_card":
                return "Cartão de crédito";
            case "cash":
                return "Dinheiro";
            default:
                return "Não informado";
        }
    }

    function paymentAlreadyMade(payment) {
        switch (payment) {
            case "pending":
                return "Não";
            case "accepted":
                return "Sim";
            default:
                return "Não informado";
        }
    }

    return load ? (
        <Loader />
    ) : (
        <div className="home_container">
            <Navbar />
            <div className="body_main">
                <div style={{ height: 100, width: "98%", alignSelf: "center", marginLeft: 30, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Segmented
                        onChange={(e) => {
                            selectedSegmented.current = e
                            GetAllOrders()
                        }}
                        style={{ backgroundColor: "transparent" }} options={['Pedidos ativos', 'Pedidos fechados']} />
                </div>
                {
                    activeOrders.map((item) => {
                        return (
                            <div style={{ alignItems: "center", display: "flex", flexDirection: "column", backgroundColor: "white", marginLeft: 30 }}>
                                <h4>Informações usuário</h4>
                                <div style={{ width: "96%", }}>
                                    <p>Data: {item.created_at.split(" ")[0]}</p>
                                    <p>Nome: {users.filter(f => f?._id.$oid == item?.id_user)[0]?.name}</p>
                                    <p>Email: {users.filter(f => f?._id.$oid == item?.id_user)[0]?.email}</p>
                                    <p>Telefone: {users.filter(f => f?._id.$oid == item?.id_user)[0]?.phone}</p>
                                    <p>Endereço: {item?.address?.street}</p>
                                    <p>Número: {item?.address?.number}</p>
                                    <p>Complemento: {item?.address?.complement}</p>
                                    <p>Bairro: {item?.address?.description}</p>
                                    <p 
                                    style={{color:item?.status === "delivered" ? "green" : "red"}}
                                    > Status: {item?.status === "delivered" ? "Entregue" : "Pendente"}</p>
                                </div>
                                <h4>Informações pedido</h4>
                                <ListOrderComponent item={item} />
                                <div style={{ width: "96%", marginTop: 20 }}>
                                    <p>Total produtos: {(item.total / 1000).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}</p>
                                    <p>Taxa de entrega: {(item.delivery_fee).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}</p>
                                    <p>Total: {((item.total / 1000) + item.delivery_fee).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}</p>
                                    <p>Forma de pagamento: {paymentMethod(item.payment)}</p>
                                    {item.change_for ? <p>Troco para: {(item.change_for).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}</p>:<></>}
                                    <p>Pago: {paymentAlreadyMade(item.payment_status)}</p>
                                    <p>Tipo de entraga: {item.delivery_type == "delivery" ? "Delivery" : "Balcão"}</p>
                                </div>
                                {
                                    item.status == "delivered" ? (
                                        <>
                                        </>
                                    ) :
                                        (
                                            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                                <DeliveryManFromOrderList idDeliveryman={item.id_deliveryman} listDeliverymans={deliveryMan} idOrder={item._id.$oid} reflashPage={reflashPage} order={item}/>
                                            </div>
                                        )
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div >
    );
}

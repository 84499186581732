import animationData from "./loader.json";
import "./Loader.css";
import React from "react";
import Lottie from 'lottie-react';

const LottieAnimation = () => {
  const containerRef = React.useRef(null);

  // Make sure the containerRef is set before rendering the Lottie component
  return (
    <div ref={containerRef} style={{ width: "100vw", height: "100vh", justifyContent: "center", display: "flex", alignItems: "center" }}>
      {/* {containerRef.current && (
        <Lottie
          loop
          autoplay
          style={{ width: '300px', height: '300px' }} // Set the desired dimensions
          renderer="svg" // Use 'svg' for React
          animationData={animationData}
        />
      )} */}
      <Lottie
        loop
        autoplay
        style={{ width: '300px', height: '300px' }} // Set the desired dimensions
        renderer="svg" // Use 'svg' for React
        animationData={animationData}
      />
    </div>
  );
};

export default LottieAnimation;

import "./carouselCard.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";

export default function CarouselCard() {
  return (
    <div className="carousel_container">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={12}
        totalSlides={3}
        orientation="horizontal"
      >
        <div className="carousel_title">
          <h4 className="label-22-bold-white margin_0">Weakly Sales</h4>
          <DotGroup className="carousel_dots" />
        </div>
        <div className="carousel_box">
          <ButtonBack>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.25 31.5L15.75 21L26.25 10.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ButtonBack>
          <Slider className="carousel_slider">
            <Slide index={0}>
              <p className="carousel_slider_content">I am the first Slide.</p>
            </Slide>
            <Slide index={1}>
              <p className="carousel_slider_content">I am the second Slide.</p>
            </Slide>
            <Slide index={2}>
              <p className="carousel_slider_content">I am the third Slide.</p>
            </Slide>
          </Slider>
          <ButtonNext>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 31.5L26.25 21L15.75 10.5"
                stroke="#FAF9FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
}

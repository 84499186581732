import { Select } from "antd";
import { NotificationService, OrderService, UserService } from "kauai-super-app-client-module/client-web";
import React, { useState } from "react";
import Button from "../button/Button";

export default function DeliveryManFromOrderList({ idDeliveryman, listDeliverymans, idOrder, reflashPage, order }) {

    const [deliveryManSelected, setDeliveryManSelected] = useState(idDeliveryman)
    const [loading, setLoading] = useState(false)

    function ChangeSelected(params) {
        setLoading(true)
        setDeliveryManSelected(params)
        console.log(idOrder)
        console.log(params)
        OrderService.acceptOrderByDeliveryman({
            id_deliveryman: params,
            id_order: idOrder
        }).then(response => {
            console.log(response)
            setLoading(false)
        }).catch(error => {
            console.error(error)
            setLoading(false)
        })
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center", marginBottom: 20 }}>
            {
                order?.delivery_type == "delivery" ? (
                    <>
                        <div>
                            <span>Atribuir motoboy: </span>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Selecione um motoboy"
                                value={deliveryManSelected}
                                loading={loading}
                                onChange={ChangeSelected}
                            >
                                <Select.Option value="">Sem motoboy</Select.Option>
                                {
                                    listDeliverymans.map((item) => {
                                        return (
                                            <Select.Option value={item._id.$oid}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </>
                ) : (
                    <>
                        <Button text="Notificar pedido pronto" style={{ width: 200 }} onClick={() => {
                            UserService.serviceUserGet().then(client => {
                                var user = client.data.filter(item => item._id.$oid == order.id_user)[0]
                                NotificationService.serviceNotificationPost({
                                    application_destination: "all",
                                    email_user: user?.email,
                                    exponentPushToken: user?.exponent_push_token,
                                    title: "Olá, " + user?.name + "!",
                                    message: "Seu pedido está pronto para ser retirado",
                                    data: {
                                        value: ""
                                    },
                                    type: "info"
                                }).then(response => {
                                    alert("Notificação enviada com sucesso")
                                })
                                .catch(error => {
                                    console.error(error)
                                })
                            }).catch(error => {
                                console.error(error)
                            })
                            // OrderService.doneOrderByDeliveryman({
                            //     id_deliveryman: deliveryManSelected,
                            //     id_order: idOrder
                            // }).then(response => {
                            //     console.log(response)
                            //     reflashPage()
                            // }).catch(error => {
                            //     console.error(error)
                            // })
                        }} />
                    </>
                )
            }
            <Button text="Finalizar pedido" style={{ width: 200 }} onClick={() => {
                OrderService.doneOrderByDeliveryman({
                    id_deliveryman: deliveryManSelected,
                    id_order: idOrder
                }).then(response => {
                    console.log(response)
                    reflashPage()
                }).catch(error => {
                    console.error(error)
                })
            }} />
        </div>
    )
}
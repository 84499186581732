import "./toBeNamedCard5.css";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
const data4pie = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
  { name: "Group E", value: 278 },
  { name: "Group F", value: 189 },
];

export default function ToBeNamedCard5() {
  return (
    <>
      <div className="card_tobenamed5">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              dataKey="value"
              data={data4pie}
              innerRadius={40}
              outerRadius={90}
              fill="#c73a3a"
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

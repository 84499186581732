import { useParams } from "react-router-dom";
import FreezerDetails from "../../components/freezerComponentDetails/freezerDetails";
import "./vendingMachineEditPage.css";

export default function VendingMachineEditPage() {
  const { id } = useParams<{ id: string | any }>();
  return (
    <>
      <div className="body_main">
        <div className="machine_edit_container">
          <FreezerDetails id={id}/>
        </div>
      </div>
    </>
  );
}

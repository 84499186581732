import "./products.css";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { Card, Space } from "antd";

export default function ProductsComponent({
  callBackClickEditProduct,
  products,
  isLoading,
}) {
  const { Meta } = Card;

  const handleEditButton = (product) => (event) => {
    event.preventDefault();
    callBackClickEditProduct(product);
  };

  return (
    <>
      <Space
        direction="horizontal"
        size="middle"
        style={{ display: "flex", margin: 10, padding: 30 }}
        className="box_product_container"
      >
        {products.map((product) => {
          return (
            <Link
              key={product._id.$oid}
              onClick={() => {handleEditButton(product)}}
              className="link_products_box_component"
            >
              
              <Card
                hoverable
                style={{ width: 350 }}
                cover={<img alt="example" src={product.picture} />}
                actions={[
                  <EditOutlined
                    style={{ fontSize: "23px", color: "#6A2A40" }}
                    key="edit"
                    rev={undefined}
                    onClick={handleEditButton(product)}
                  />,
                ]}
                className="products_edit"
              >
                <Meta
                  title={product.name}
                  description={product.description}
                  style={{ height: 200, overflow: "scroll", overflowY: "scroll" }}
                />
                <br></br>
                <div className="product_price_code_box">
                  <p className="product_name_box">
                    {product.price} {product.price_tag}
                  </p>
                  <p className="product_code_box">{product.code}</p>
                </div>
              </Card>
            </Link>
          );
        })}
      </Space>
    </>
  );
}

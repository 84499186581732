import { useEffect, useRef, useState } from "react";
import "./productDetailsEditComponent.css";
import {
  Button,
  ConfigProvider,
  Form,
  Image,
  Input,
  InputNumber,
  Space,
  Switch,
  notification,
  Modal,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd/es/form";
import { useShowBoxContext } from "../../context/boxRestaurantContext";
import type { NotificationPlacement } from "antd/es/notification/interface";
import CreateComplementComponent from "../createComplementComponent/createComplementComponent";
import plusIcon from "../../icons/plusIcon.svg";
import secureLocalStorage from "react-secure-storage";
import { LoadingOutlined } from "@ant-design/icons";
import { updateProductsInRestaurant } from "../../services/products";
import { UploadFile } from "../../services/file";
import React from "react";
import { getRestaurantById } from "../../services/restaurants";


export default function ProductEditComponent({
  Formins,
  isOpen,
}: {
  Formins: FormInstance;
  isOpen: boolean;
}) {
  interface Products {
    _id: {
      $oid: string;
    };
    name: string;
    description: string;
    price: number;
    price_tag: string;
    food_region: string;
    size: number;
    promotion: string;
    special_offers: [];
    category: string;
    is_enable: boolean;
    quantity: number;
    is_visible: boolean;
    complements: {
      max: number;
      items: [];
    };
    picture: string;
    optional_complements: [];
    code: string;
    border_pizza?: [];
  }
  interface Complements {
    id_complement: string;
    code: string;
    name: string;
    description: string;
    price: number;
    picture: string;
  }
  const [isCombo, setIsCombo] = useState<boolean>(false);
  const [saleOutOfOperation, setSaleOutOfOperation] = useState<boolean>(false);
  const [itemImage, setItemImage] = useState<any>(null);
  const [itemImageLoading, setItemImageLoading] = useState<boolean>(false);
  const { isEdit } = useShowBoxContext();
  const [filterredProduct, setFilterredProduct] = useState<Products[]>([]);
  const [selected, setSelected] = useState<any>();
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [formIsEnable, setFormIsEnable] = useState<boolean>();
  const [submittable, setSubmittable] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputleRef = useRef(null);
  const openNotificationSuccess = (
    placement: NotificationPlacement,
    values: any
  ) => {
    api.success({
      message: `Operação concluida com sucesso!`,
      description: `${values.name} foi modificado com sucesso`,
      placement,
      duration: 3.5,
      onClose: () => {
        window.location.reload();
      },
    });
  };
  const openNotificationError = (placement: NotificationPlacement) => {
    api.error({
      message: `Operação fracassada`,
      description: `Por favor verifique as informações colocadas`,
      placement,
      duration: 3,
    });
  };

  const values = Form.useWatch([], Formins);

  const stylesInput = {
    borderColor: "#6A2A40",
  };

  useEffect(() => {
    const restaurantId = secureLocalStorage.getItem("restaurant");

    if (typeof restaurantId === 'string') {
      getRestaurantById({ restaurantId }).then((response) => {
        const filtered = response.data.products.filter(
          (item: any) => ["acompanhamento", "ADICIONAIS", "PIZZA GRANDE", "PIZZA MEDIA", "PIZZA PEQUENA"].includes(item.category)
        );
        console.log(response.data);
        setFilterredProduct(filtered);
        const values = Formins.getFieldsValue(["complements", "items"]);
        console.log(values);
      })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("Restaurant ID is not valid:", restaurantId);
    }
  }, []);



  useEffect(() => {
    if (isEdit) {
      setFormIsEnable(Formins.getFieldValue("is_enable"));
      const validated_combo = Formins.getFieldValue("combo_itens");
      const validated_sale_out_of_operation = Formins.getFieldValue(
        "sale_out_of_operation"
      );
      setSaleOutOfOperation(validated_sale_out_of_operation);
      console.log("id item ->", Formins.getFieldValue("_id"));
      const restaurantId = secureLocalStorage.getItem("restaurant");
      console.log("id restaurante ->", restaurantId);

      const image = Formins.getFieldValue("picture");
      if (image !== "string") {
        setItemImage(image);
      }
      if (validated_combo && validated_combo.length > 0) {
        console.log("is combo");
        setIsCombo(true);
      }
      if (formIsEnable === false) {
        setIsBlocked(true);
      } else {
        setIsBlocked(false);
      }
      Formins.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    } else {
      setIsCombo(false);
      setItemImage(null);
      Formins.resetFields();
    }
  }, [formIsEnable, isBlocked, isEdit, values, Formins]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const restaurantId = secureLocalStorage.getItem("restaurant") as string;

    if (!restaurantId) {
      openNotificationError("top");
      setLoading(false);
      return;
    }

    const itemId = values._id.$oid as string;

    await updateProductsInRestaurant({
      restaurantId,
      productId: itemId,
      requestBody: { ...values, picture: itemImage },
    })
      .then((response) => {
        console.log(response);
        openNotificationSuccess("top", values);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        openNotificationError("top");
        setLoading(false);
      });
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo.values);
    openNotificationError("top");
  };

  const onChangeBlockItem = (checked: boolean) => {
    setIsBlocked(checked);
    setFormIsEnable(checked);
    console.log(formIsEnable);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataProduct = () => {
    console.log("dataProduct");
    const restaurantId = secureLocalStorage.getItem("restaurant");

    if (typeof restaurantId === 'string') {
      getRestaurantById({ restaurantId }).then((response) => {
        const filtered = response.products.filter(
          (item: any) => ["acompanhamento", "COMPLEMENTO", "ADICIONAIS", "PIZZA GRANDE"].includes(item.category)
        );
        setFilterredProduct(filtered);
        setIsModalOpen(false);
      })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else {
      if (typeof restaurantId !== 'string') {
        console.error("Invalid restaurant ID:", restaurantId);
      }
    }
  };



  function handleUploadImageProfile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setItemImage(null);
      setItemImageLoading(true)
      UploadFile({ file: selectedFile })
        .then((response) => {
          console.log("response ->", response.files[0].url);
          setItemImage(response.files[0].url);
          Formins.setFieldsValue({ picture: response.files[0].url });
          setItemImageLoading(!itemImageLoading);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  }
  return (
    <>
      <h3>Editar Produto</h3>
      {contextHolder}
      <Form.Provider>
        <Form
          name="basic"
          onFinish={onFinish}
          form={Formins}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          disabled={isBlocked}
        >
          <Form.Item
            name="picture"
            label="Imagem do Item"
            rules={[
              { required: false, message: "Por favor, carregue uma imagem" },
            ]}
          >
            <div
              style={{
                display: "flex",
                borderRadius: 20,
                border: "3px dotted #6A2A40",
                width: "350px",
                justifyContent: "center",
                alignItems: "center",
                height: "250px",
                padding: "5px",
                cursor: "pointer",
              }}
              //@ts-ignore
              onClick={() => fileInputleRef.current.click()}
            >
              {itemImage ? (
                <>
                  <img
                    src={itemImage}
                    alt="imagem do produto"
                    className="image-profile"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: 20,
                    }}
                  />
                </>
              ) : (
                <>
                  {
                    itemImageLoading ? (
                      <LoadingOutlined rev={undefined} />
                    ) : (
                      <h2
                        style={{
                          minWidth: "100%",
                          minHeight: "100%",
                          borderRadius: 20,
                          background: "#6A2A40",
                          marginBottom: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Upload imagem
                      </h2>
                    )

                  }
                </>
              )}
            </div>
            <input
              type="file"
              onChange={handleUploadImageProfile}
              ref={fileInputleRef}
              style={{ display: "none" }}
            />
          </Form.Item>
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Inserir o nome do produto" }]}
          >
            <Input style={stylesInput} />
          </Form.Item>
          <Form.Item
            label="id"
            name={["_id", "$oid"]}
            rules={[{ required: true, message: "Inserir o nome do produto" }]}
            hidden
          >
            <Input style={stylesInput} />
          </Form.Item>
          <Form.Item
            label="Preço"
            name="price"
            rules={[{ required: true, message: "Inserir o preço do produto" }]}
          >
            <InputNumber
              style={stylesInput}
              step="0.01"
              prefix="R$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              { required: true, message: "Inserir a descrição do produto" },
            ]}
          >
            <Input style={stylesInput} />
          </Form.Item>
          <Form.Item
            label="Codigo"
            name="code"
            rules={[
              {
                required: true,
                message: "Inserir o código colibri do produto",
              },
            ]}
          >
            <Input style={stylesInput} />
          </Form.Item>
          <Form.Item
            label="Categoria"
            name="category"
            rules={[
              {
                required: true,
                message: "Inserir a categoria do produto",
              },
            ]}
          >
            <Input style={stylesInput} />
          </Form.Item>
          <div>
            <strong>Complementos:</strong>
            {isBlocked ? (
              <p style={{ color: "red" }}>
                <strong>
                  Não pode adicionar complementos enquanto o produto estiver
                  bloqueado
                </strong>
              </p>
            ) : (
              <p></p>
            )}
          </div>
          <Form.List name={["complements", "items"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...field }) => (
                  <Space key={key} align="baseline">
                    <Form.Item
                      {...field}
                      name={[name, "picture"]}
                      rules={[
                        {
                          type: "url",
                          message: "Please enter a valid URL",
                        },
                      ]}
                    >
                      <Image
                        src={Formins.getFieldValue([
                          "complements",
                          "items",
                          key,
                          "picture",
                        ])}
                        width="100px"
                        height="100px"
                        style={{
                          borderRadius: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Nome"
                      name={[name, "name"]}
                      rules={[{ required: false, message: "Missing price" }]}
                    >
                      <Input style={stylesInput} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Descrição"
                      name={[name, "description"]}
                      rules={[{ required: false, message: "Adicione uma descrição" }]}
                    >
                      <Input style={stylesInput} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Preço"
                      name={[name, "price"]}
                      rules={[{ required: true, message: "Missing price" }]}
                    >
                      <InputNumber
                        style={stylesInput}
                        step="0.01"
                        prefix="R$"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      rev={undefined}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          {!isBlocked ? (
            <div
              className="create_product_form_dropdown_box"
              style={{ margin: "30px" }}
            >
              <select
                id="dropdown"
                onChange={(value) => {
                  const selectedProduct = filterredProduct.find(
                    (obj) => obj.name === value.target.value
                  );
                  if (selectedProduct) {
                    const newObject: Complements[] = [
                      {
                        id_complement: selectedProduct._id.$oid,
                        name: selectedProduct.name,
                        code: selectedProduct.code,
                        description: selectedProduct.description,
                        price: selectedProduct.price,
                        picture: selectedProduct.picture,
                      },
                    ];
                    const formValues = Formins.getFieldsValue();
                    formValues.complements.items.push(...newObject);
                  }
                  setSelected({});
                }}
                value={selected}
              >
                <option>Adicione um novo complemento</option>
                {filterredProduct.map((products) => (
                  <>
                    <option key={products.name} value={products.name}>
                      {products.category} - {products.name}
                    </option>
                  </>
                ))}
              </select>
              <button
                type="button"
                onClick={showModal}
                className="button_products"
              >
                <img src={plusIcon} alt="" />
              </button>
              <Modal
                title="Cria o teu complemento aqui"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: true, hidden: true }}
                cancelButtonProps={{ disabled: true, hidden: true }}
              >
                <div>
                  <CreateComplementComponent refreshData={dataProduct} />
                </div>
              </Modal>
            </div>
          ) : (
            <div></div>
          )}

          <Form.Item
            label="Maximo de Complementos"
            name={["complements", "max"]}
          >
            <InputNumber style={stylesInput} />
          </Form.Item>
          {Formins.getFieldValue("border_pizza") ? (
            <div>
              <strong>Borda da Pizza</strong>
              <Form.List name="border_pizza">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          style={stylesInput}
                          {...restField}
                          name={[name, "code"]}
                          rules={[
                            { required: true, message: "por favor por codigo" },
                          ]}
                        >
                          <Input placeholder="Código" />
                        </Form.Item>
                        <Form.Item
                          style={stylesInput}
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "por favor por descrição",
                            },
                          ]}
                        >
                          <Input placeholder="Descrição" />
                        </Form.Item>
                        <Form.Item
                          style={stylesInput}
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            { required: true, message: "por favor por nome" },
                          ]}
                        >
                          <Input placeholder="Nome" />
                        </Form.Item>
                        <Form.Item
                          style={stylesInput}
                          {...restField}
                          name={[name, "picture"]}
                          hidden
                        >
                          <Input placeholder="Picture" value={""} />
                        </Form.Item>
                        <Form.Item
                          style={stylesInput}
                          {...restField}
                          name={[name, "price"]}
                          rules={[
                            { required: true, message: "por favor por preço" },
                          ]}
                        >
                          <InputNumber
                            style={stylesInput}
                            step="0.01"
                            prefix="R$"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }
                            parser={(value) =>
                              value!.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          rev={undefined}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Adicionar Borda
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          ) : (
            <div></div>
          )}
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#6A2A40",
                fontSize: 15,
                fontSizeSM: 15,
              },
            }}
          >
            <Form.Item label="Desbloquear/Bloquear Produto" name="is_enable">
              <Switch
                checked={formIsEnable}
                checkedChildren="disponivel"
                unCheckedChildren="bloqueado"
                disabled={false}
                onChange={onChangeBlockItem}
              />
            </Form.Item>
          </ConfigProvider>
          <Form.Item
            label="Direcionar para o whatsapp quando o app estiver fechado?"
            name="sale_out_of_operation"
            rules={[
              {
                required: false,
                message:
                  "Este produto pode ser direcionado para o whatsapp quando o app estiver fechado?",
              },
            ]}
          >
            <Switch
              checkedChildren="Sim"
              checked={saleOutOfOperation}
              unCheckedChildren="Não"
              onChange={() => setSaleOutOfOperation(!saleOutOfOperation)}
            />
          </Form.Item>
          <Form.Item
            label="É um combo?"
            name="combo"
            rules={[
              { required: false, message: "Inserir se o produto é combo" },
            ]}
          >
            <Switch
              checkedChildren="Sim"
              checked={isCombo}
              unCheckedChildren="Não"
              disabled={Boolean(
                Formins.getFieldValue("combo_itens") &&
                Formins.getFieldValue("combo_itens").length > 0
              )}
              onChange={() => setIsCombo(!isCombo)}
            />
          </Form.Item>
          {isCombo ? (
            <Form.List name="combo_itens">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        style={stylesInput}
                        {...restField}
                        name={[name, "code"]}
                        rules={[
                          { required: true, message: "por favor por codigo" },
                        ]}
                      >
                        <Input placeholder="Código" />
                      </Form.Item>
                      <Form.Item
                        style={stylesInput}
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          { required: true, message: "por favor por nome" },
                        ]}
                      >
                        <Input placeholder="Nome" />
                      </Form.Item>

                      <Form.Item
                        style={stylesInput}
                        {...restField}
                        name={[name, "price"]}
                        rules={[
                          { required: true, message: "por favor por preço" },
                        ]}
                      >
                        <InputNumber
                          style={stylesInput}
                          step="0.01"
                          prefix="R$"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          }
                          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        rev={undefined}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Adicionar Produto no combo
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : (
            <></>
          )}
          <Form.Item wrapperCol={{ offset: 8 }}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#6A2A40",
                  fontSize: 18,
                  fontSizeSM: 20,
                  borderRadius: 20,
                  paddingContentHorizontal: 70,
                },
              }}
            >
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                disabled={!submittable}
              >
                Salvar alterações
              </Button>
            </ConfigProvider>
          </Form.Item>
        </Form>
      </Form.Provider>
    </>
  );
}

import {
    GoogleMap,
    LoadScript,
    GoogleMapProps,
    MarkerF,
    Marker,
    useJsApiLoader
  } from "@react-google-maps/api";

export default function MarkerDelivery({
    markers
}) {
    return (
      <>
        {markers.map((value, index) => {
          return (
            <div key={String(value.id)}>
              <Marker
                key={index}
                position={{ lat: value.position.lat, lng: value.position.lng }}
                options={{
                  optimized: true,
                  visible: true,
                  label: {
                    text: value?.name === undefined ? "Não identificado" : value?.name,
                    className: "marker-label",
                  }
                }}
                icon={{
                  url: "https://bohocasasstorage.blob.core.windows.net/boho-casas/pin_delivery.png",
                  scaledSize: new window.google.maps.Size(70, 80),
                }}
              />
            </div>
          );
        })}
      </>
    );
  }
import { useEffect, useState } from "react";
import "./createProductComponent.css";
import plusIcon from "../../icons/plusIcon.svg";
import CreateComplementComponent from "../createComplementComponent/createComplementComponent";
import { addProductRestaurant } from "../../services/restaurants";
import {
  Button,
  ConfigProvider,
  Form,
  Image,
  Input,
  InputNumber,
  Space,
  Modal,
  Select,
  Upload,
  notification
} from "antd";
import {
  MinusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import secureLocalStorage from "react-secure-storage";
import server from "../../config/server";

export default function CreateProductComponent({ productsParams }) {
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState(productsParams);
  const [filterredProduct, setFilterredProduct] = useState([]);
  const [selected, setSelected] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [imageToSend, setImageToSend] = useState("");

  const openNotificationSuccess = (placement, values) => {
    api.success({
      message: `Operação concluida com sucesso!`,
      description: `${values.name} foi criado com sucesso`,
      placement,
      duration: 3.5,
      onClose: () => {
        console.log("closed notification");
        window.location.reload();
      },
    });
  };
  const openNotificationError = (placement) => {
    api.error({
      message: `Operação fracassada`,
      description: `Por favor verifique as informações colocadas`,
      placement,
      duration: 3,
      onClose: () => {
        console.log("closed notification");
      },
    });
  };
  const { Option } = Select;
  const stylesInput = {
    borderColor: "#6A2A40",
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataProduct = () => {
  };

  useEffect(() => {
    const filterred = products.filter(
      (item) => item.category === "acompanhamento" || item.category === "COMPLEMENTO" || item.category === "ADICIONAIS"
    );
    setFilterredProduct(filterred);
  }, []);

  const onFinish = (values) => {
    console.log("Success:", values);

    const restaurantId = secureLocalStorage.getItem("restaurant");
    if (typeof restaurantId !== 'string') {
      console.error("Invalid restaurant ID:", restaurantId);
      openNotificationError("top");
      return;
    }

    const productData = {
      code: values.code,
      name: values.name,
      description: values.description,
      price: values.price,
      price_tag: "R$",
      food_region: "",
      size: 1,
      promotion: "",
      special_offers: [],
      category: values.category,
      type_of_product: "",
      is_enable: true,
      quantity: 1,
      is_visible: true,
      complements: {
        max: values.complements.max,
        items: values.complements.items,
      },
      photos: [],
      picture: imageToSend,
      general_info: [],
      optional_complements: [],
    };

    addProductRestaurant({ restaurantId, product: productData }).then((response) => {
      console.log(response.data);
      form.resetFields();  // Limpa os campos do formulário
      openNotificationSuccess("top", values);
      document.location.reload();  // Recarrega a página
    })
      .catch((error) => {
        console.error(error);
        openNotificationError("top");
      });
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo.values);
    openNotificationError("top")
  };

  return (
    <>
      <div className="create_product_container">
        <Form.Provider>
          <Form
            name="basic"
            onFinish={onFinish}
            form={form}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="picture"
              label="Image Upload"
              rules={[
                {
                  required: true,
                  message: "Por favor inserir uma imagem primeiro",
                },
              ]}
            >
              <Upload
                listType="picture-card"
                maxCount={1} // Allow only one image to be uploaded
                action={server + "/restaurants/add-image"}
                onChange={(info) => {
                  setImages(info.fileList);
                  if (info.file.status === "done") {
                    setImageToSend(info?.file?.response?.files[0].url);
                  }
                }}
                fileList={images}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: "Inserir o nome do produto" }]}
            >
              <Input style={stylesInput} />
            </Form.Item>
            <Form.Item
              label="Preço"
              name="price"
              rules={[
                { required: true, message: "Inserir o preço do produto" },
              ]}
            >
              <InputNumber
                style={stylesInput}
                step="0.01"
                prefix="R$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              label="Descrição"
              name="description"
              rules={[
                { required: true, message: "Inserir a descrição do produto" },
              ]}
            >
              <Input style={stylesInput} />
            </Form.Item>
            <Form.Item
              label="Categoria"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Inserir a categoria",
                },
              ]}
            >
              <Input style={stylesInput} />
            </Form.Item>
            <Form.Item
              label="Codigo"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Inserir o código colibri do produto",
                },
              ]}
            >
              <Input style={stylesInput} />
            </Form.Item>
            <Form.List name={["complements", "items"]}>
              {(fields, { add, remove }) => (
                <>
                  <div
                    className={
                      fields.length === 0
                        ? ""
                        : "create_product_form_complements"
                    }
                  >
                    {fields.map((field, index) => (
                      <>
                        <div className="create_product_form_inserted_complements">
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              name={[field.name, "picture"]}
                              rules={[
                                {
                                  type: "url",
                                  message: "Please enter a valid URL",
                                },
                              ]}
                            >
                              <Image
                                src={form.getFieldValue([
                                  "complements",
                                  "items",
                                  index,
                                  "picture",
                                ])}
                                width="100px"
                                height="100px"
                                style={{
                                  borderRadius: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Nome"
                              name={[field.name, "name"]}
                              rules={[
                                { required: false, message: "Missing price" },
                              ]}
                            >
                              <Input disabled style={stylesInput} />
                            </Form.Item>
                            <Form.Item
                              label="Peço"
                              name={[field.name, "price"]}
                              rules={[
                                { required: false, message: "Missing price" },
                              ]}
                            >
                              <InputNumber
                                style={stylesInput}
                                step="0.01"
                                prefix="R$"
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    "."
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                              rev={undefined}
                            />
                          </Space>
                        </div>
                      </>
                    ))}
                  </div>
                  <div
                    className="create_product_form_dropdown_box"
                    style={{ margin: "30px" }}
                  >
                    <select
                      id="dropdown"
                      onChange={(value) => {
                        const selectedProduct = filterredProduct.find(
                          (obj) => obj.name === value.target.value
                        );
                        if (selectedProduct) {
                          const newObject = {
                            id_complement: selectedProduct._id.$oid,
                            name: selectedProduct.name,
                            code: selectedProduct.code,
                            description: selectedProduct.description,
                            price: selectedProduct.price,
                            picture: selectedProduct.picture,
                          };

                          add(newObject);
                        }
                        setSelected({});
                      }}
                      value={selected}
                    >
                      <option>Adicione um novo complemento</option>
                      {filterredProduct.map((products) => (
                        <>
                          <option key={products.name} value={products.name}>
                            {products.name}
                          </option>
                        </>
                      ))}
                    </select>
                    <button
                      type="button"
                      onClick={showModal}
                      className="button_products"
                    >
                      <img src={plusIcon} alt="" />
                    </button>
                  </div>
                </>
              )}
            </Form.List>
            <Form.Item
              label="Maximo de Complementos"
              name={["complements", "max"]}
            >
              <InputNumber style={stylesInput} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8 }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#6A2A40",
                    fontSize: 18,
                    fontSizeSM: 20,
                    borderRadius: 20,
                    paddingContentHorizontal: 70,
                  },
                }}
              >
                <Button type="primary" htmlType="submit" disabled={false}>
                  Criar Produto
                </Button>
              </ConfigProvider>
            </Form.Item>
          </Form>
        </Form.Provider>
        <Modal
          title="Cria o teu complemento aqui"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ disabled: true, hidden: true }}
          cancelButtonProps={{ disabled: true, hidden: true }}
        >
          <div>
            <CreateComplementComponent refreshData={dataProduct} />
          </div>
        </Modal>
      </div>
    </>
  );
}

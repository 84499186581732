import React, { useState } from "react";
import "./listOrder.css";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function renderFoods(value) {
    return (
        <div style={{ width: "96%", marginLeft: "2%" }}>
            <div style={{ width: "100%", display: "flex", gap: 10, flexDirection: "column" }}>
                <div style={{ width: "100%", gap: 10, display: "flex", alignItems: "center" }}>
                    <div style={{ flex: .3, height: "100%", width: "100%" }}>
                        <span style={{ textAlign: "center" }}>Produto: </span>
                    </div>
                    <div style={{ flex: 4, height: "100%", width: "100%" }}>
                        <div style={{ height: 100, flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <div style={{ width: 120 }}>
                                <img src={value.picture} style={{ height: 80, width: 80, borderRadius: 20 }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span>{value.name}</span>
                                <span>cod colibri: {value.code}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", gap: 10, display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <div style={{ flex: .3, height: "100%", width: "100%" }}>
                        {
                            value.complements.length == 0 ? (
                                <h4></h4>
                            ) : (
                                <span style={{ textAlign: "center" }}>{value.category == "PIZZAS" ? "Sabor: " : "Complementos: "}</span>
                            )
                        }
                    </div>
                    <div style={{ flex: 4, height: "100%", width: "100%", display: "flex", gap: 5 }}>
                        <>
                            {
                                value.complements.map(result => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "column", height: 40, backgroundColor: "whitesmoke", justifyContent: "center", borderRadius: 20, padding: 5, overflow: "scroll" }}>
                                            <span>{result.name}</span>
                                            <span>cod colibri: {result.code}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    </div>
                </div>
                <div style={{ width: "100%", gap: 10, display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <div style={{ flex: .3, height: "100%", width: "100%" }}>
                        <span style={{ textAlign: "center" }}>Observação: </span>
                    </div>
                    <div style={{ flex: 4, height: "100%", width: "100%", display: "flex", gap: 5 }}>
                        <span>{value.observation}</span>
                    </div>
                </div>
            </div>
            <div style={{ height: 1, backgroundColor: "gainsboro" }} />
        </div>
    )
}

export default function ListOrderComponent({ item }) {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div style={{ width: "100%", backgroundColor: "white", alignSelf: "center", borderRadius: 20 }}>
            <div className="shadow-div" onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer", backgroundColor: "white", height: 50, width: "100%", position: "relative", alignItems: "center", justifyContent: "center", display: "flex" }}>
                <h4 style={{ textAlign: "center" }}>Total produtos {item.products.length}</h4>
                <div style={{ position: "absolute", right: 20, top: 10, cursor: "pointer" }}>
                    {
                        !isOpen ? (
                            <IoIosArrowDown size={30} />
                        ) : (
                            <IoIosArrowUp size={30} />
                        )
                    }
                </div>
            </div>
            {
                isOpen && (
                    <div>
                        {
                            item.products.map(itemProducts => {
                                return (
                                    renderFoods(itemProducts)
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}
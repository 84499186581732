import axios from "axios";
import server from "./server";


const http = axios.create({
    baseURL: `${server}`,
  });
  
  http.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        },
        (error:any) => {
            return Promise.reject(error);
        }
    );
    

export default http;
import { useEffect, useState } from "react";
import { Switch, Col, Row, Input, Descriptions, Form, Button, message, Spin } from 'antd';
import secureLocalStorage from "react-secure-storage";
import "./RestaurantPage.css";
import { restaurantsAll } from "../../services/restaurants";
import { update_Opening_Hour } from "../../services/restaurants";
import { update_Restaurant_Working } from "../../services/restaurants";

export default function RestaurantsInformation() {
    const [propertyInfo, setPropertyInfo] = useState([])
    const [info, setInfo] = useState({})
    const [descriptionInfo, setDescriptionInfo] = useState([])
    const [checkRestaurant, setCheckRestaurant] = useState(false)


    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setCheckRestaurant(checked);
        const restaurantId = secureLocalStorage.getItem("restaurant");
        update_Restaurant_Working(restaurantId, checked).then((res) => {
            console.log(res);
            message.success("Restaurante atualizado com sucesso");
        }).catch((err) => {
            console.error(err);
            message.error("Erro ao atualizar restaurante");
        });
    }

    useEffect(() => {
        var restaurantId = secureLocalStorage.getItem("restaurant");
        restaurantsAll().then((res) => {
            var restaurant = res.data.filter((r) => r._id.$oid === restaurantId)[0];
            setPropertyInfo(restaurant);
            setCheckRestaurant(restaurant.working);
            var keys = [];
            Object.keys(restaurant).forEach((key) => {
                if (
                    key == "_id" ||
                    key == "name" ||
                    key == "cpnj" ||
                    key == "fantasy_name" ||
                    key == "condominium_name" ||
                    key == "street" ||
                    key == "number" ||
                    key == "complement" ||
                    key == "city" ||
                    key == "state" ||
                    key == "country" ||
                    key == "zip_code" ||
                    key == "latitude" ||
                    key == "longitude" ||
                    key == "email"
                ) {
                    keys.push({
                        "key": key,
                        "label": key,
                        "children": Object.values(restaurant[key])
                    });
                }

            });;
            setInfo(restaurant);
            console.log(keys, "restaurant")

            keys.sort((a, b) => (a.key > b.key) ? 1 : -1)

            setDescriptionInfo(keys);
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    const days = [{
        label: "Segunda",
        key: "monday"
    }, {
        label: "Terça",
        key: "tuesday"
    }, {
        label: "Quarta",
        key: "wednesday"
    }, {
        label: "Quinta",
        key: "thursday"
    }, {
        label: "Sexta",
        key: "friday"
    }, {
        label: "Sábado",
        key: "saturday"
    }, {
        label: "Domingo",
        key: "sunday"
    }];

    return (
        <>
            {
                Object.keys(propertyInfo).length > 0 ? (
                    <div className="body_main">
                        <div className="container_home">

                            <Descriptions
                                title="Informação do Restaurante"
                                items={descriptionInfo}
                                bordered
                            />
                            {
                                Object.keys(propertyInfo).length > 0 && (
                                    <>
                                        <div style={{
                                            height: "80px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                            <span style={{ fontWeight: "700", margin: "5px" }}>
                                                Horário de funcionamento
                                            </span>
                                        </div>
                                        {
                                            days.map((day) => {
                                                return (
                                                    <Form
                                                        key={day.key}
                                                        onFinish={(values) => {
                                                            const restaurantId = secureLocalStorage.getItem("restaurant");
                                                            const dayKey = day.key;

                                                            update_Opening_Hour(restaurantId, dayKey, {
                                                                early_morning: values.early_morning,
                                                                end_morning: values.end_morning,
                                                                early_afternoon: values.early_afternoon,
                                                                end_afternoon: values.end_afternoon,
                                                                nights_start: values.nights_start,
                                                                nights_end: values.nights_end
                                                            }).then((res) => {
                                                                console.log(res);
                                                                message.success("Horário atualizado com sucesso");
                                                            }).catch((err) => {
                                                                console.error(err);
                                                                message.error("Erro ao atualizar horário");
                                                            });
                                                        }}


                                                    >
                                                        <Row style={{ alignItems: "center", display: "flex", border: "1px solid #d9d9d9", padding: "16px" }}>
                                                            <Col className="grid-container" span={3}>
                                                                <span>{day.label}</span>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>Manhã</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].early_morning}
                                                                    name={"early_morning"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="input-name"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Início manhã"}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>ás</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].end_morning}
                                                                    name={"end_morning"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="input-name"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Fim manhã"} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>Tarde</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].early_afternoon}
                                                                    name={"early_afternoon"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="early_afternoon"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Início tarde"} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>ás</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].end_afternoon}
                                                                    name={"end_afternoon"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="end_afternoon"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Fim tarde"} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>Noite</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].nights_start}
                                                                    name={"nights_start"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="nights_start"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Início noite"} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container" span={1}>
                                                                <span>ás</span>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item
                                                                    initialValue={propertyInfo?.opening_hours[day.key].nights_end}
                                                                    name={"nights_end"}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "* Compo obrigatório"
                                                                    }, {
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0) {
                                                                                const nomeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                                                                                if (!nomeRegex.test(value)) {
                                                                                    return Promise.reject('Insira um horário válido ex(10:20)');
                                                                                } else {
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }

                                                                        }
                                                                    }]}>
                                                                    <Input
                                                                        id="nights_end"
                                                                        style={{ width: "100%" }}
                                                                        placeholder={"Fim noite"} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className="grid-container-bottom" span={2}>
                                                                <Form.Item>
                                                                    <Button type="primary" htmlType="submit" >Salvar</Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        </div>
                        <div style={{ width: "100%", height: 50, justifyContent: "center", display: "flex" }}>
                            <span style={{ marginRight: 50 }}>Restaurante está {checkRestaurant ? "ativo" : "inativo"}</span>
                            <Switch checked={checkRestaurant} onChange={onChange} />
                        </div>
                    </div>
                ) : (
                    <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin size="large" />
                    </div>
                )
            }
        </>
    );
}
import React, { useEffect, useMemo, useState } from "react";
import "../Map/map.css";
import {
  GoogleMap,
  LoadScript,
  GoogleMapProps,
  MarkerF,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCoordsContext } from "../../context/coordsContext";
import MarkerDelivery from "../../components/markers/markersMotoboy";
import MarkerRestaurant from "../../components/markers/markersRestaurant";
import { restaurantsAll } from "../../services/restaurants";
import { get_All_Deliveryman } from "../../services/deliveryman";

const Map = () => {
  const refMap = React.useRef(null);
  const [map, setMap] = React.useState(null);
  const [mapKey, setMapKey] = useState(new Date().getTime());
  const [markers, setMarkers] = useState([]);
  const [markersRestaurant, setMarkersRestaurant] = useState([]);
  const { coords, addCoords } = useCoordsContext();
  const position = useMemo(() => ({ lat: -11.857491, lng: -55.50621 }), []);
  const containerStyle = {
    width: "98%",
    height: "100%",
    marginLeft: "5px",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD6fOT8rSbECcaRqpKQL94QKtgtDss4jbw",
  });

  useEffect(() => {
    if (coords != null) {
      try {
        if (map && markers.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(coords);
          map.fitBounds(bounds);
          const maxZoom = map.getZoom();
          map.setZoom(14);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [coords]);

  useEffect(() => {
    get_All_Deliveryman()
      .then((response) => {
        const filtered = response.data.filter(f => f.current_location != null)
        const simplifiedItems = filtered.map((item) => ({
          id: item._id.$oid,
          name: item.name,
          position: {
            lat: parseFloat(item.current_location.latitude),
            lng: parseFloat(item.current_location.longitude),
          },
        }));
        setMarkers(...markers, simplifiedItems);
      })
      .catch((error) => {
        console.log(error);
      });

      restaurantsAll().then((response) => {
        const simplifiedItems = response.data.map((item) => ({
          id: item._id.$oid,
          name: item.name,
          position: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
        }));
        setMarkersRestaurant(...markersRestaurant, simplifiedItems);
      })
      .catch((error) => {
        console.log(error);
      });

    setInterval(() => {
      get_All_Deliveryman()
        .then((response) => {
          console.log("HERE!");
          console.log(response.data);
          const filtered = response.data.filter(f => f.current_location != null)
          const simplifiedItems = filtered.map((item) => ({
            id: item._id.$oid,
            name: item.name,
            position: {
              lat: parseFloat(item?.current_location?.latitude),
              lng: parseFloat(item?.current_location?.longitude),
            },
          }));
          setMarkers(...markers, simplifiedItems);
        })
        .catch((error) => {
          console.log(error);
        });
        restaurantsAll().then((response) => {
          const simplifiedItems = response.data.map((item) => ({
            id: item._id.$oid,
            name: item.name,
            position: {
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
            },
          }));
          setMarkersRestaurant(...markersRestaurant, simplifiedItems);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 5000);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  function FitToMarkersDeliveryMans() {
    // try {
    //   if (map && markers.length > 0) {
    //     const bounds = new window.google.maps.LatLngBounds();

    //     markers.forEach((marker) => {
    //       bounds.extend(marker.position);
    //     });

    //     map.fitBounds(bounds);
    //     console.log(bounds.Ia.hi);
    //     console.log(bounds.Ia.lo);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    try {
      if (map && markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();

        var cpMarkers = Object.assign([]);
        cpMarkers.push(...markers);

        console.log("FitToMarkersRestaurants");
        console.log(cpMarkers);

        cpMarkers.forEach((marker) => {
          bounds.extend(marker.position);
        });

        map.fitBounds(bounds);
        console.log(bounds.Ia.hi);
        console.log(bounds.Ia.lo);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function FitToMarkersRestaurants() {
    try {
      if (map && markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();

        var cpMarkers = Object.assign([]);
        cpMarkers.push(...markersRestaurant);

        console.log("FitToMarkersRestaurants");
        console.log(cpMarkers);

        cpMarkers.forEach((marker) => {
          bounds.extend(marker.position);
        });

        map.fitBounds(bounds);
        console.log(bounds.Ia.hi);
        console.log(bounds.Ia.lo);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={14}
        key={mapKey}
        ref={refMap}
        streetView={false}
        options={{
          // mapTypeId: "satellite",
          streetView: false,
          streetViewControl: false,
        }}
        onLoad={(mapdata) => {
          setMap(mapdata);
        }}
        onUnmount={onUnmount}
      >
        {markers.length > 0 && (
          <>
            <MarkerDelivery markers={markers} />
            <MarkerRestaurant markers={markersRestaurant} />
          </>
        )}
        {
          <div className="custom-tools-container">
            <div className="custom-tools">
              <button
                onClick={FitToMarkersRestaurants}
                className="buttom-custom-tools"
              >
                Kauai
              </button>
            </div>

            <div className="custom-tools">
              <button
                onClick={FitToMarkersDeliveryMans}
                className="buttom-custom-tools"
              >
                Todos Motoboys
              </button>
            </div>
          </div>
        }
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default Map;


import server from "../config/server";

interface updateProductsInRestaurantProps {
    file: File;
}

export async function UploadFile({file}: updateProductsInRestaurantProps) {
    const OpenAPI = server
    const url = `${OpenAPI}/restaurants/add-image`;
    console.log('file', file)
    let formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            console.log('response', response)
            if (response.status === 422) {
                throw new Error('Validation Error');
            }
            throw new Error('Some error occurred');
        }

        return await response.json();
    } catch (error) {
        console.error('Error making the request:', error);
        throw error;
    }
}

import './toBeNamedCard1.css'

interface ToBeNamedCard1Props {
  title?: string;
  info?: string;
}
export default function ToBeNamedCard1({ title, info }: ToBeNamedCard1Props) {
  return (
    <div className="card_tobenamed1">
      <h4>{title}</h4>
      <div className="card_info1">
        <p>{info}</p>
      </div>
    </div>
  );
}

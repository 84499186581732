import React from "react";
import { useSelector } from "react-redux";
import { Route, Navigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";

// Create a ProtectedRoute component.
const ProtectedRoute = ({ element, redirectPath }) => {
  const restaurant = useSelector((state) => state.restaurant); // Correctly extract the user from the state
  const token = secureLocalStorage.getItem("restaurant");

  const isAuthenticated = () => {
    if (token !== null) {
      return true;
    } else {
      return false;
    }
  };

  if (isAuthenticated()) {
    return element;
  } else {
    return <Navigate to={redirectPath} />;
  }
};

export default ProtectedRoute;

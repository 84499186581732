import React, { Dispatch, SetStateAction } from 'react';
import './Input.css';

interface InputProps {
    type: string;
    placeholder: string;
    setValue: Dispatch<SetStateAction<string>>;
}

const Input: React.FC<InputProps> = (props) => {
    return (
        <input type={props.type} placeholder={props.placeholder} className="input" onChange={x => props.setValue(x.target.value)} />
    );
}

export default Input;

import { FreezerService } from "kauai-super-app-client-module/client-web";
import { useState, useEffect } from "react";
import FreezerCam from "../freezerCamComponent/freezerCam";
import FreezerInfo from "../freezerInformationComponent/freezerInfo";
import Button from "../../components/button/Button";
import "./freezerDetails.css";
export default function FreezerDetails(id) {
  const [freezer, setFreezers] = useState({});

  useEffect(() => {
    FreezerService.getAllFreezer()
      .then((response) => {
        const filteredFreezer = response.data.filter(
          (freezer) => freezer._id.$oid === id.id
        );
        setFreezers(filteredFreezer[0]);
      })
      .catch((error) => {
        console.error(error);
      });
    var interval = setInterval(() => {
      FreezerService.getAllFreezer()
        .then((response) => {
          const filteredFreezer = response.data.filter(
            (freezer) => freezer._id.$oid === id.id
          );
          setFreezers(filteredFreezer[0]);
        })
        .catch((error) => {
          console.error(error);
        });
      return () => clearInterval(interval);
    }, 3000);

  }, []);
  return (
    <>
      <div className="freezer_box_1">
        <FreezerCam freezer={freezer} />
      </div>
      <div className="freezer_box_2">
        <FreezerInfo freezer={freezer} />
        <Button
          text="Abrir Geladeira"
          style={{
            width: "10vw",
            border: 0,
            borderRadius: "20px",
            marginTop: "3rem",
          }}
          onClick={() => { }}
        />
      </div>
    </>
  );
}

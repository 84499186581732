import { Modal, Form, Skeleton, Card, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
export default function CardLoading() {
  const { Meta } = Card;
  return (
    <>
      <Skeleton paragraph={{ rows: 0 }} active />
      <Space
        direction="horizontal"
        size="middle"
        style={{ display: "flex", margin: 10, padding: 30 }}
        className="box_product_container"
      >
        <Card
          style={{ width: 350 }}
          actions={[<EditOutlined key="edit" rev={undefined} />]}
        >
          <Skeleton loading active>
            <Meta title="" description="" />
          </Skeleton>
        </Card>
        <Card
          style={{ width: 350 }}
          actions={[<EditOutlined key="edit" rev={undefined} />]}
        >
          <Skeleton loading active>
            <Meta title="" description="" />
          </Skeleton>
        </Card>
        <Card
          style={{ width: 350 }}
          actions={[<EditOutlined key="edit" rev={undefined} />]}
        >
          <Skeleton loading active>
            <Meta title="" description="" />
          </Skeleton>
        </Card>
      </Space>
    </>
  );
}

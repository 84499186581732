import "./devicesInfoCard.css";
import { InfoDeviceService } from "kauai-super-app-client-module/client-web";
import React, { useState, useEffect } from "react";

export default function DevicesInfoCard() {
  interface Devices {
    brand: string;
    date: string;
    id_device_application: string;
    id_info_device: string;
    is_online: boolean;
    platform_api_level: string;
    _id: { $oid: string };
  }

  const [devices, setDevices] = useState<Devices[]>([]);
  useEffect(() => {
    InfoDeviceService.serviceInfoDeviceGet()
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {
        console.error("ERROR " + error);
      });
  }, []);

  return (
    <>
      <div className="devices_container">
        <h4>Devices Online</h4>
        <div className="devices_info">
          {devices.map((device) => {
            return (
              <div>
                <p>{device.brand}</p>
                <p>{device.date}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

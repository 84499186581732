import "./freezerCam.css";
import { Image } from "antd";
export default function FreezerCam({ freezer }) {
  const { cam_one, cam_two, cam_three, cam_four } = freezer;

  return (
    <>
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <Image
          width={300}
          src={cam_one ? "data:image/png;base64," + cam_one : "error"}
          fallback="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"
          className="image_freezer"
        />
        <Image
          width={300}
          src={cam_two ? "data:image/png;base64," + cam_two : "error"}
          fallback="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"
          className="image_freezer"
        />
        <Image
          width={300}
          src={cam_three ? "data:image/png;base64," + cam_three : "error"}
          fallback="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"
          className="image_freezer"
        />
        <Image
          width={300}
          src={cam_four ? "data:image/png;base64," + cam_four : "error"}
          fallback="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"
          className="image_freezer"
        />
      </Image.PreviewGroup>
    </>
  );
}

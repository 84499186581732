import http from "../config/axios";

interface DeliveryAddress {
    group?: string | undefined;
    neighborhood: string;
    city: string;
    value: any;
}

export async function get_Delivery_Address() {
    const url = "/delivery_address";
    try {
        const response = await http
            .request({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                url,
            });
        return response.data;
    } catch (error) {
        console.error("Error fetching delivery address:", error);
        throw error;
    }
}

export async function add_Delivery_Address({ requestBody }: { requestBody: DeliveryAddress }) {
    const url = `/delivery_address`;

    try {
        const response = await http.request({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url,
            data: requestBody,
        });
        return response.data;
    } catch (error: any) {
        console.error("Erro ao adicionar endereço de entrega:", error.response ? error.response.data : error);
        throw error;
    }
}


export async function update_Delivery_Address({ addressId, requestBody }: { addressId: string, requestBody: DeliveryAddress }) {
    const url = `/delivery_address/${addressId}?id_delivery_address=${addressId}`;

    const requestBodyWithId = {
        ...requestBody,
    };

    try {
        const response = await http.put(url, requestBodyWithId, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    } catch (error: any) {
        console.error("Erro ao atualizar endereço de entrega:", error.response ? error.response.data : error);
        throw error;
    }
}

export async function delete_Delivery_Address({ addressId }: { addressId: string }) {
    const url = `/delivery_address/${addressId}?id_delivery_address=${addressId}`;

    try {
        const response = await http.delete(url, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    } catch (error: any) {
        console.error("Erro ao deletar endereço de entrega:", error.response ? error.response.data : error);
        throw error;
    }
}

import "./RestaurantPage.css";
import plusIcon from "../../icons/plusIcon.svg";
import ProductsComponent from "../../components/productsComponent/products";
import { useEffect, useState } from "react";
import CreateProductComponent from "../../components/createProductComponent/createProductComponent";
import { useShowBoxContext } from "../../context/boxRestaurantContext";
import { Modal, Form, Button } from "antd";
import { LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import ProductEditComponent from "../../components/productDetailsEditComponent/productDetailsEditComponent";
import CardLoading from "../../components/cardAntLoading/cardAntLoading";
import secureLocalStorage from "react-secure-storage";
import ActiveAndDeactiveProductsModal from "../../components/activeAndDeactiveProductsModal/activeAndDeactiveProductsModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateOrderInProducts } from "../../services/products";
import { restaurantsAll } from "../../services/restaurants";
import { update_List_Of_Products } from "../../services/products";
export default function RestaurantPage() {
  const [productSelected, setProductSelected] = useState({});
  const [itemsSelected, setItemsSelected] = useState("");
  const [isThereProducts, setIsThereProducts] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [groupProducts, setGroupProducts] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [listToUpdate, setListToUpdate] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editedOrder, setEditedOrder] = useState(false);
  const { isBoxVisible, showCreateProductBox, isEdit, showEditProduct } =
    useShowBoxContext();
  const [form] = Form.useForm();

  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [formattedItems, setFormattedItems] = useState([]);
  var restaurantId = secureLocalStorage.getItem("restaurant");

  useEffect(() => {
    restaurantsAll().then((res) => {
      var restaurant = res.data.filter((r) => r._id.$oid === restaurantId)[0];

      var products = restaurant.products_all.filter(
        (f) => f.category !== "COMPLEMENTO"
      );

      setAllProducts(products);
      setIsThereProducts(true);
      const groupByCategory = products.reduce((group, product) => {
        const { category } = product;
        group[category] = group[category] ?? [];
        group[category].push(product);
        return group;
      }, {});
      delete groupByCategory["COMPLEMENTO"];

      const items = Object.keys(groupByCategory).map((key, index) => ({
        id: `item-${index}`, // Crie um identificador único
        name: key, // O nome é a categoria
        content: groupByCategory[key], // O conteúdo é o grupo de produtos
      }));
      console.log("newItems", items);
      setFormattedItems(items);

      setGroupProducts(groupByCategory);
    })
      .catch((err) => {
        console.error(err);
      });
  }, [restaurantId]);

  const firstLetterUpperCase = (word) => {
    const newWord = word.charAt(0).toUpperCase() + word.slice(1);
    word = newWord;
    return word;
  };

  const handleFilterCategory = (key) => {
    setItemsSelected(key);
  };

  const handleOkProduct = () => {
    showCreateProductBox();
  };

  const handleCancelProduct = () => {
    showCreateProductBox();
  };

  const handleOkEdit = () => {
    showEditProduct();
  };

  const handleCancelEdit = () => {
    form.resetFields();
    showEditProduct();
  };

  function callBackClickEditProduct(value) {
    console.log("CALL BACK CLICKED ");
    console.log(value);
    setProductSelected(value);
    showEditProduct();
  }
  useEffect(() => { }, [productSelected]);
  form.setFieldsValue(productSelected);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    console.log("chamou");
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      formattedItems,
      result.source.index,
      result.destination.index
    );
    setFormattedItems(newItems);

    setEditedOrder(true);
  };

  function saveOrder() {
    setCategoriesLoaded(true);
    const orderCategories = formattedItems.map((item, index) => ({
      name: item.name,
      index_order: index + 1,
    }));

    updateOrderInProducts({
      restaurantId: restaurantId,
      order_products: orderCategories,
    }).then(() => {
      setCategoriesLoaded(false);
      setEditedOrder(false);
    });
  }
  return (
    <>
      <div className="body_main">
        <div className="products_container">
          <div className="products_navbar">
            <h3 className="products_navbar_item">Produtos</h3>

            <div className="products_navbar_item">
              <button onClick={() => showCreateProductBox()}>
                <img src={plusIcon} alt="plusIcon" />
                <span>Adicionar Produto</span>
              </button>
              {editedOrder && (
                <button
                  onClick={() => saveOrder()}
                  className="button_update_order"
                >
                  <span>Salvar alterações de ordem</span>
                </button>
              )}
              <div
                onClick={() => setItemsSelected(null)}
                className={
                  !itemsSelected
                    ? `category_item_all ative`
                    : `category_item_all`
                }
              >
                TODOS
              </div>
              {categoriesLoaded ? (
                <LoadingOutlined
                  style={{ fontSize: "30px", color: "#6A2A40", margin: 30 }}
                />
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                          padding: 8,
                        }}
                      >
                        {formattedItems.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: "none",
                                  padding: 6,
                                  margin: `0 0 8px 0`,
                                  background: "#FFF",
                                  color: "#6a2a40",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div
                                  onClick={() =>
                                    handleFilterCategory(item.name)
                                  }
                                  className={
                                    itemsSelected === item.name
                                      ? `category_item ative`
                                      : `category_item`
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p style={{ flex: 1 }}>
                                      {firstLetterUpperCase(item.name)}
                                    </p>
                                    <SettingOutlined
                                      style={{ marginBottom: 10 }}
                                      onClick={() => {
                                        setModalSettings(true);
                                        setProductSelected(item.content);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {isThereProducts ? (
                <div></div>
              ) : (
                <div>
                  <LoadingOutlined
                    style={{ fontSize: "30px", color: "#6A2A40", margin: 30 }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="products_category_container">
            {isThereProducts ? (
              <div></div>
            ) : (
              <div>
                <CardLoading />
              </div>
            )}
            {Object.keys(groupProducts)
              .filter((key) => !itemsSelected || key === itemsSelected)
              .map((key, index) => {
                return (
                  <div key={key + index} className="products_category_box">
                    <div className="products_category_text">
                      {firstLetterUpperCase(key)}
                    </div>
                    <ProductsComponent
                      callBackClickEditProduct={callBackClickEditProduct}
                      products={groupProducts[key]}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <Modal
          width={"50%"}
          style={{ top: 10 }}
          open={isBoxVisible}
          onCancel={handleCancelProduct}
          onOk={handleOkProduct}
          okButtonProps={{ disabled: true, hidden: true }}
          cancelButtonProps={{ disabled: true, hidden: true }}
        >
          <CreateProductComponent
            productsParams={allProducts}
            restaurantId={localStorage.getItem("restaurant")}
          />
        </Modal>
        <Modal
          width={"50%"}
          style={{ top: 10 }}
          open={isEdit}
          onCancel={handleCancelEdit}
          onOk={handleOkEdit}
          okButtonProps={{ disabled: true, hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          {isEdit && <ProductEditComponent Formins={form} isOpen={isEdit} />}
        </Modal>
        <Modal
          width={"50%"}
          style={{ top: 10 }}
          open={modalSettings}
          onCancel={() => {
            // setModalSettings(false)
            if (needReload) window.location.reload();
            else setModalSettings(false);
          }}
          onOk={handleOkEdit}
          okButtonProps={{ disabled: true, hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "98%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>{productSelected[0]?.category}</h5>
            <h6>Aqui você pode ativar/desativar os produtos dessa categoria</h6>
            <ActiveAndDeactiveProductsModal
              products={productSelected}
              setNeedReload={setNeedReload}
              setListToUpdate={setListToUpdate}
              listToUpdate={listToUpdate}
            />
            <Button
              disabled={listToUpdate.length == 0}
              loading={loadingButton}
              onClick={() => {
                setLoadingButton(true);
                console.log("listToUpdate", listToUpdate);
                update_List_Of_Products({
                  restaurantId: restaurantId,
                  requestBody: listToUpdate
                })
                  .then((res) => {
                    console.log(res);
                    setLoadingButton(false);
                    // setModalSettings(false);
                    window.location.reload();
                  })
                  .catch((err) => {
                    setLoadingButton(false);
                    console.error(err);
                  });
              }}
            >
              Salvar
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

import "./Home.css";
import LottieAnimation from "../Loader/Loader";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import LoginTab from "../../components/logintab/logintab";
import DevicesInfoCard from "../../components/devicesInfoCard/devicesInfoCard";
import CarouselCard from "../../components/carouselCard/carouselCard";
import ToBeNamedCard1 from "../../components/toBeNamedCard1/toBeNamedCard1";
import ToBeNamedCard2 from "../../components/toBeNamedCard2/toBeNamedCard2";
import ToBeNamedCard3 from "../../components/toBeNamedCard3/toBeNamedCard3";
import ToBeNamedCard4 from "../../components/toBeNamedCard4/toBeNamedCard4";
import ToBeNamedCard5 from "../../components/toBeNamedCard5/toBeNamedCard5";
import ToBeNamedCard6 from "../../components/toBeNamedCard6/toBeNamedCard6";

export default function Home() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    console.log("View" + Home.name + " OPENED");
    loading();
    return () => {
      console.log("View" + Home.name + " CLOSED");
    };
  }, []);
  async function loading() {
    console.log("Loading...");
    await new Promise((resolve) => setTimeout(resolve, 3000));
    console.log("Loaded!");
    setLoad(true);
  }
  return (
    <>
      {!load ? (
        <LottieAnimation />
      ) : (
        <div className="home_container">
          <Navbar />
          <div className="body_main">
            <LoginTab />
            <div className="container_home">
              <div className="first_row">
                <DevicesInfoCard />
                <ToBeNamedCard1 />
                <ToBeNamedCard2 />
              </div>
              <div className="second_row ">
                <CarouselCard />
                <ToBeNamedCard3 />
                <ToBeNamedCard4 />
              </div>
              <div className="third_row">
                <ToBeNamedCard5 />
                <ToBeNamedCard6 />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import "./navbar.css";
import iconDelivery from "../../icons/delivery.svg";
import iconHome from "../../icons/homeIcon.svg";
import iconRestaurant from "../../icons/restaurantIcon.svg";
import iconSpa from "../../icons/spaIcon.svg";
import iconDropdown from "../../icons/dropdownIcon.svg";
import iconArrowRight from "../../icons/arrowRightIcon.svg";
import firstLogo from "../../icons/firstLogo.svg";
import secondLogo from "../../icons/firstLogo.svg";
import notificationIcon from "../../icons/notificationIcon.svg";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import condominium from "../../icons/ic_user-check.svg"
import { MdOutlineRestaurant } from 'react-icons/md';
import { MdDeliveryDining } from 'react-icons/md';
import { MdNotificationsActive } from 'react-icons/md';
import { FaUserShield } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { AiFillHome } from 'react-icons/ai';
import { FaListUl } from 'react-icons/fa';
import { BsFillBagCheckFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";


export default function Navbar() {
  const restaurant = secureLocalStorage.getItem("restaurant");

  const restaurantRedux = useSelector((state) => state.restaurant);

  if (restaurant !== null) {
    return (
      <nav className="navbar">
        <ul className="navbar_nav">
          <ul className="logo_container">
            <li className="logo">
              <img src={firstLogo} alt="delivery image" />
            </li>
            <li className="second_logo">
              <img src={secondLogo} alt="delivery image" />
            </li>
          </ul>

          <li className="nav_item">
            <a href="/restaurant-information" className="nav_link">
              {/* <img src={iconHome} alt="delivery image" /> */}
              <AiFillHome size={30} color="white" />
              <span className="link_text label-16-bold">Restaurante</span>
            </a>
          </li>

          <li className="nav_item">
            <a href="/delivery-address" className="nav_link">
              {/* <img src={iconHome} alt="delivery image" /> */}
              <MdDeliveryDining size={30} color="white" />
              <span className="link_text label-16-bold">Endereços</span>
            </a>
          </li>

          <li className="nav_item">
            <a href="/restaurant" className="nav_link">
              {/* <img src={iconHome} alt="delivery image" /> */}
              <MdOutlineRestaurant size={30} color="white" />
              <span className="link_text label-16-bold">Menu</span>
            </a>
          </li>

          <li className="nav_item">
            <a href="/order-list" className="nav_link">
              {/* <img src={iconHome} alt="delivery image" /> */}
              <BsFillBagCheckFill size={30} color="white" />
              <span className="link_text label-16-bold">Pedidos</span>
            </a>
          </li>


          <li className="nav_item">
            <a href="/delivery" className="nav_link">
              {/* <img src={iconDelivery} alt="delivery image" /> */}
              <FaMapMarkerAlt size={30} color="white" />
              <span className="link_text label-16-bold">Mapa</span>
            </a>
          </li>
          {/* <li className="nav_item">
            <a href="/vendingmachine" className="nav_link">
              <img src={iconRestaurant} alt="delivery image" />
              <span className="link_text label-16-bold">Geladeira</span>
            </a>
          </li> */}
          <li className="nav_item">
            <a href="/notify" className="nav_link">
              {/* <img src={notificationIcon} alt="notify image" /> */}
              <MdNotificationsActive size={30} color="white" />
              <span className="link_text label-16-bold">Notificar</span>
            </a>
          </li>
          <li className="nav_item">
            <a href="/manager-condominium" className="nav_link">
              {/* <img src={condominium} alt="notify image" /> */}
              <FaUserShield size={30} color="white" />
              <span className="link_text label-16-bold">Gestor condôminos</span>
            </a>
          </li>
          {/* <li className="nav_item">
          <a href="#action3" className="nav_link">
            <img src={iconSpa} alt="delivery image" />
            <span className="link_text label-16-bold">Spa</span>
          </a>
        </li>
        <li className="nav_item">
          <div className="nav_link">
            <img src={iconDropdown} alt="delivery image" />
            <span className="link_text label-16-bold">Dropdown</span>
          </div>
        </li> */}
          <li className="nav_item">
            <a href="/restaurant-logs" className="nav_link">
              {/* <img src={iconHome} alt="delivery image" /> */}
              <FaListUl size={30} color="white" />
              <span className="link_text label-16-bold">Logs</span>
            </a>
          </li>
          <>
            <li className="nav_item">
              <span onClick={() => {
                secureLocalStorage.removeItem("restaurant");
                window.location.href = "/auth/login";
              }} className="link_text label-16-bold" style={{ color: "white", marginLeft: 80, marginRight: 20, position: "absolute", cursor: "pointer" }}>Sair</span>
              {/* <img src={iconArrowRight} alt="delivery image" /> */}
              <div style={{ justifyContent: "flex-end", display: "flex", marginRight: 20 }}>
                <FiArrowRight size={30} color="white" />
              </div>
            </li>
          </>

        </ul>
      </nav>
    );
  } else {
    return null;
  }
}

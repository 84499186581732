import "./freezerInfo.css";
export default function FreezerInfo({ freezer }) {
  const { id_freezer, created_at, updated_at } = freezer;
  return (
    <>
      <div className="freezer_info_box">
        <h3>Informaçoes da geladeira:</h3>
        <div>
          <div>
            <strong>ID:</strong> {id_freezer}
          </div>
          <div>
            <strong>Criado:</strong> {created_at}
          </div>
          <div>
            <strong>Actualizado:</strong> {updated_at}
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import "./Button.css";

interface ButtonProps {
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button className="button" onClick={props.onClick} style={props.style}>
      {props.text}
    </button>
  );
};

export default Button;

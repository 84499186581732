import "./logintab.css";
import "reactjs-popup/dist/index.css";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Space, Popover } from "antd";
import { useState } from "react";

export default function LoginTab() {
  const [clicked, setClicked] = useState(false);
  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };
  const hide = () => {
    setClicked(false);
  };
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
      <button onClick={hide}>Close</button>
    </div>
  );
  return (
    <div className="login_tab">
      <div className="login_user">
        <Popover
          placement="bottom"
          content={content}
          title="Opções de Utilizador"
          trigger="click"
          open={clicked}
          onOpenChange={handleClickChange}
        >
          <Avatar size={54} icon={<UserOutlined rev={undefined} />} />
        </Popover>
      </div>
    </div>
  );
}

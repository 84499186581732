import NotificationSender from "../../components/pushNotificationComponent/notificationSender";
import "./notificationPage.css";
import { Card, Image, List } from "antd";
import confirmationIamge from "./confirmationImage.svg";
import { UserService } from "kauai-super-app-client-module/client-web";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

interface NotificationInterface {
  title: string;
  message: string;
  type: string;
}
export default function NotificationPage<T>() {
  const [load, setLoad] = useState(true);
  const queryClient = new QueryClient();
  const [notificationTemplate, setNotificationTemplate] = useState<
    NotificationInterface[]
  >([]);

  const [notificationClicked, setNotificationClicked] =
    useState<NotificationInterface>();
  const notificationCookie = Cookies.get("notificationTemplate");

  function loading() {
    console.log("Loading...");
    if (notificationCookie) {
      try {
        const parsedNotification = JSON.parse(notificationCookie);
        setNotificationTemplate(parsedNotification);
      } catch (error) {
        console.error("Error parsing cookie:", error);
      }
    } else {
      console.error("Notification cookie not found.");
    }
    console.log("Loaded!");
    setLoad(true);
  }

  useEffect(() => {
    const elementToAnimate = document.querySelector(".image_notification");
    const formToAnimate = document.querySelector(".form_notification");
    elementToAnimate?.classList.remove("image_notification");
    elementToAnimate?.classList.add("image_animation");
    formToAnimate?.classList.remove("form_notification");
    formToAnimate?.classList.add("form_animation");
    loading();
  }, []);

  useEffect(() => {
    console.log("NOTIFICATION TEMPLATE", notificationTemplate);
    Cookies.set("notificationTemplate", JSON.stringify(notificationTemplate), {
      expires: 7,
    });
  }, [notificationTemplate]);

  return (
    <div className="body_main">
      <div>
        <div className="notification_container">
          <div className="notification_details">
            <h3>
              <strong>Pagina de Notificação</strong>
            </h3>
            <p>
              <strong>Envia a tua notificação aos teus clientes!</strong>
            </p>
            <Image
              width={300}
              src={confirmationIamge}
              preview={false}
              className="image_notification"
            />
          </div>

          <div className="form_notification" style={{flex: 1.5}}>
            <QueryClientProvider client={queryClient}>
              <NotificationSender
                setNotificationTemplate={setNotificationTemplate}
                notificationTemplate={notificationTemplate}
                notificationClick={notificationClicked as NotificationInterface}
              />
            </QueryClientProvider>
          </div>

          <div className="notification_details_all" style={{flex: 3}}>
            <div className="notification_all_box" style={{width: "90%"}}>
              <List
                header={
                  <div
                    className="notification_details_title"
                    style={{ fontWeight: "bold" }}
                  >
                    Notificações enviadas
                  </div>
                }
                bordered={true}
                itemLayout="horizontal"
                dataSource={notificationTemplate}
                loading={!load}
                renderItem={(item, index) => (
                  <Card
                    hoverable
                    onClick={() => {
                      console.log(item);
                      setNotificationClicked(item);
                    }}
                    className="products_edit"
                  >
                    <div className="notification_box">
                      <h3>{item.title}</h3>
                      <p>{item.message}</p>
                      <p>{item.type}</p>
                    </div>
                  </Card>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Switch } from "antd";
import React from "react";
import secureLocalStorage from "react-secure-storage";

export default function SwitchActiveAndDeactiveProducts({ product, setNeedReload, setListToUpdate, listToUpdate }) {
    
    const [isEnable, setIsEnable] = React.useState(product.is_enable);
    const [isLoading, setIsLoading] = React.useState(false);

    const restaurant = secureLocalStorage.getItem("restaurant");

    return (
        <Switch loading={isLoading} defaultChecked={product.is_enable} checked={product.is_enable} onChange={(e) => {
            // setIsLoading(true);
            
            var pd = product;
            pd.is_enable = e;

            if (listToUpdate.filter((p) => p.code === pd.code).length == 0) {
                setListToUpdate([...listToUpdate, pd]);
            }else {
                var newList = listToUpdate.filter((p) => p.code !== pd.code);
                setListToUpdate(newList);
            }
            
            // RestaurantsService.updateProducts(product._id.$oid, restaurant, pd).then((res) => {
            //     console.log(res);
            //     setIsEnable(e);
            //     setIsLoading(false);
            //     setNeedReload(true);
            // }).catch((err) => {
            //     console.error(err);
            //     setIsLoading(false);
            // })
        }}/>
    )
}
import { ProductsService } from "kauai-super-app-client-module/client-web";
import { useState } from "react";
import "./createComplementComponent.css";
interface CreateComplementComponent{
    refreshData: () => any
}
export default function CreateComplementComponent(refreshData: CreateComplementComponent) {

  interface Products {
    name: string;
    description: string;
    price: number;
    price_tag: string;
    food_region: string;
    size: number;
    promotion: string;
    special_offers: [];
    category: string;
    is_enable: boolean;
    quantity: number;
    is_visible: boolean;
    complements: {
      max: number;
      items: [];
    };
    picture: string;
    optional_complements: [];
    code: string;
  }
  const [formValues, setFormValues] = useState<any>({
    picture: "",
    name: "",
    description: "",
    price: 0,
    category: "acompanhamento",
    code: "",
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target?.result;
        if (base64String && typeof base64String === "string") {
          setFormValues({
            ...formValues,
            picture: base64String.split(",")[1],
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const resetFrom = () => {
    setFormValues({
      picture: "",
      name: "",
      description: "",
      price: 0,
      category: "acompanhamento",
      code: "",
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(formValues);
    ProductsService.addProducts({
        code: formValues.code,
        name: formValues.name,
        description: formValues.description,
        price: formValues.price,
        price_tag: "R$",
        food_region: "",
        size: 1,
        promotion: "",
        special_offers: [],
        category: formValues.category,
        type_of_product: "",
        is_enable: true,
        quantity: 1,
        is_visible: true,
        complements: {
            max: 0,
            items: []
        },
        photos: [],
        picture: formValues.picture,
        general_info: [],
        optional_complements: [],
    }).then((response)=>{
        refreshData.refreshData()
        console.log(response.data)
    }).catch((error)=>{
        console.error(error)
    });
    setFormValues({
      picture: "",
      name: "",
      description: "",
      price: 0,
      category: "",
      code: "",
    });
  };

  return (
    <>
      <div className="create_complement_form">
        <form onSubmit={handleSubmit}>
          <label htmlFor="image">Foto do Produto</label>
          <div className="create_complement_form_picture">
            <input
              type="file"
              id="picture"
              name="picture"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileChange}
            />
            {formValues.picture && (
              <div>
                <img
                  src={`data:image/jpeg;base64,${formValues.picture}`} // Use the correct MIME type
                  alt="Uploaded Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    borderRadius: 100,
                    boxShadow: "0px 0px 5px 1px",
                  }}
                />
              </div>
            )}
          </div>
          <div className="create_complement_form_name_description">
            <label htmlFor="product_name">Nome do Produto</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
            />
            <label htmlFor="description">Descrição</label>
            <input
              type="text"
              name="description"
              id="description"
              value={formValues.description}
              onChange={handleInputChange}
            />
          </div>

          <div className="create_complement_form_price_category">
            <label htmlFor="price">Preço</label>
            <input
              type="number"
              name="price"
              id="price"
              value={formValues.price}
              onChange={handleInputChange}
            />
            <label htmlFor="category">Categoria</label>
            <input
              type="text"
              name="category"
              id="category"
              value={formValues.category}
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="code">Codigo de Colibri</label>
            <input
              type="text"
              name="code"
              id="code"
              value={formValues.code}
              onChange={handleInputChange}
            />
          </div>
          <input
            type="submit"
            value="Criar complemento"
            className="create_complement_form_submit"
          />
          <button
            type="reset"
            value="Reset aos Valores"
            className="create_complement_form_reset"
            onClick={resetFrom}
          >
            Reset aos valores
          </button>
        </form>
      </div>
    </>
  );
}

import "./notificationSender.css";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import {
  NotificationService,
  UserService,
} from "kauai-super-app-client-module/client-web";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import type { NotificationPlacement } from "antd/es/notification/interface";

interface NotificationInterface {
  title: string;
  message: string;
  type: string;
}

interface NotificationComponentProps {
  setNotificationTemplate: React.Dispatch<
    React.SetStateAction<NotificationInterface[]>
  >;
  notificationTemplate: NotificationInterface[];
  notificationClick: NotificationInterface;
}

const NotificationSender: React.FC<NotificationComponentProps> = ({
  setNotificationTemplate,
  notificationTemplate,
  notificationClick,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const { TextArea } = Input;
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  const openNotificationSuccess = (placement: NotificationPlacement) => {
    api.success({
      message: `Operação concluida com sucesso!`,
      description: `A notificação foi enviada com sucesso`,
      placement,
      duration: 4,
      onClose: () => {
        console.log("closed notification");
      },
    });
  };
  const isNotificationRepeated = (
    newNotification: any,
    existingNotifications: any
  ) => {
    return existingNotifications.some((notification: any) => {
      return (
        notification.title === newNotification.title &&
        notification.message === newNotification.message &&
        notification.type === newNotification.type
      );
    });
  };
  const onFinish = (values: any) => {
    const newNotification = {
      title: values.title,
      message: values.message,
      type: values.type,
    };

    if (!isNotificationRepeated(newNotification, notificationTemplate)) {
      setNotificationTemplate([...notificationTemplate, newNotification]);
    } else {
      console.warn("Notification is repeated");
    }
    console.log("Success:");

    UserService.serviceUserGet()
      .then((response) => {
        response.data?.forEach((users) => {
          NotificationService.serviceNotificationPost({
            exponentPushToken: users.exponent_push_token,
            email_user: users.email,
            message: values.message,
            title: values.title,
            type: values.type,
            application_destination: "all",
            data: {
              value: "",
            },
          })
            .then((responseNotification) => {
              console.log(responseNotification);
            })
            .catch((error) => {
              console.error(error);
            });
        });
        form.resetFields();
        openNotificationSuccess("topRight");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    console.log("CLICKKKK", notificationClick);
    form.setFieldsValue(notificationClick);
  }, [notificationClick]);
  return (
    <>
      {contextHolder}
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        //initialValues={{ title: notificationClick.title }}
      >
        <Form.Item
          label="Titulo"
          name="title"
          rules={[
            { required: true, message: "Introduza o seu titulo primeiro" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mensagem"
          name="message"
          rules={[{ required: true, message: "Só falta a mensagem!" }]}
        >
          <TextArea allowClear />
        </Form.Item>

        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            { required: true, message: "Coloca aqui o teu tipo de alerta" },
          ]}
        >
          <Select
            placeholder="Tipo de notificação"
            style={{ width: 170 }}
            options={[
              { value: "alert", label: "Alertar" },
              { value: "info", label: "Informar" },
              { value: "error", label: "Erros" },
            ]}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#6A2A40",
                fontSize: 16,
                borderRadius: 20,
              },
            }}
          >
            <Button type="primary" htmlType="submit" disabled={!submittable}>
              <strong>Enviar</strong>
            </Button>
          </ConfigProvider>

          <ConfigProvider
            theme={{
              token: {
                colorLink: "#6A2A40",
                fontSize: 16,
                borderRadius: 20,
              },
            }}
          >
            <Button type="link" onClick={onReset}>
              Reset
            </Button>
          </ConfigProvider>
        </Form.Item>
      </Form>
    </>
  );
};

export default NotificationSender;

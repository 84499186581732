import "./toBeNamedCard6.css";
import {
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Line,
  LineChart,
} from "recharts";

export default function ToBeNamedCard6() {
  const data4line = [
    {
      name: "Mes A",
      onevalue: 3000,
      othervalue: 3400,
      amt: 2400,
    },
    {
      name: "Mes B",
      onevalue: 3000,
      othervalue: 1398,
      amt: 2210,
    },
    {
      name: "Mes C",
      onevalue: 3000,
      othervalue: 3800,
      amt: 6290,
    },
    {
      name: "Mes D",
      onevalue: 2780,
      othervalue: 3908,
      amt: 2000,
    },
    {
      name: "Mes E",
      onevalue: 1890,
      othervalue: 4800,
      amt: 2181,
    },
    {
      name: "Mes F",
      onevalue: 2390,
      othervalue: 3800,
      amt: 2500,
    },
    {
      name: "Mes G",
      onevalue: 3490,
      othervalue: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className="card_tobenamed6">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data4line}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="onevalue" stroke="#79767b" />
          <Line type="monotone" dataKey="othervalue" stroke="#c73a3a" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { OrderService } from "kauai-super-app-client-module/client-web";
import { List, Typography } from "antd";

export default function Logs() {

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        OrderService.returnAllLogs().then((res) => {
            var logsDesc = res.data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setLogs(logsDesc);
        }).catch((err) => { });

        setInterval(() => {
            console.log("updating logs");
            OrderService.returnAllLogs().then((res) => {
                var logsDesc = res.data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setLogs(logsDesc);
            }).catch((err) => { });
        }, 5000);
    }, []);

    return (
        <div className="body_main">
            <List
                dataSource={logs}
                renderItem={(item) => {
                    return (
                        <List.Item>
                            <List.Item.Meta
                                title={<a>{item.id_user} - {item.created_at}</a>}
                                description={item.info}
                            />
                        </List.Item>
                    );
                }}
            />
        </div>
    );
}
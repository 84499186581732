import { Switch } from "antd";
import React from "react";
import SwitchActiveAndDeactiveProducts from "./switchActiveAndDeactiveProducts/switchActiveAndDeactiveProducts";

export default function ActiveAndDeactiveProductsModal({ products, setNeedReload, setListToUpdate, listToUpdate }) {
    return (
        <div>
            {/* <div style={{ display: "flex", width: 400, justifyContent: "space-between" }}>
                <p>DESATIVAR TODOS </p>
                <Switch defaultChecked={isEnable} checked={isEnable} onChange={(e) => {
                    setIsEnable(e);
                    console.log(e);
                }} />
            </div> */}
            {
                products.map((product) => {
                    return (
                        <div style={{ display: "flex", width: 400, justifyContent: "space-between" }}>
                            <p>{product.name}</p>
                            <SwitchActiveAndDeactiveProducts product={product} setNeedReload={setNeedReload} setListToUpdate={setListToUpdate} listToUpdate={listToUpdate}/>
                        </div>
                    )
                })
            }
        </div>
    )
}
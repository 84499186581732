import React, { useState, useEffect } from "react";
import Button from "../../components/button/Button";
import Input from "../../components/inputs/Input";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRestaurant } from "../../features/restaurant/restaurant";
import secureLocalStorage from "react-secure-storage";
import { loginUser } from "../../services/login";
import { restaurantsAll } from "../../services/restaurants";

export default function Login() {
  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectRestaurant, setSelectedRestaurant] = useState("");
  const [allRestaurants, setAllRestaurants] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getAllRestaurants() {
    restaurantsAll().then(response => {
        console.log("Response RestaurantsAll", response.data);
        setAllRestaurants(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function login() {
    if (allRestaurants && allRestaurants.length > 0) {
      let selectedRestaurant = selectRestaurant 
        ? allRestaurants.find(restaurant => restaurant.name === selectRestaurant)
        : allRestaurants[0];
  
      // Verifica se selectedRestaurant está definido e possui a propriedade _id antes de acessá-la.
      if (selectedRestaurant && selectedRestaurant._id && selectedRestaurant._id.$oid) {
        console.log("selectedRestaurantID", selectedRestaurant._id.$oid);
  
        // Processo de login
        loginUser({ email, password }).then((response) => {
          console.log("Response", response);
          secureLocalStorage.setItem("restaurant", selectedRestaurant._id.$oid);
          dispatch(saveRestaurant(selectedRestaurant));
          navigate("/delivery");
        }).catch((error) => {
          console.error(error);
          toast.error("Email ou senha incorreto", { type: "error" });
        });
      } else {
        console.error("Selected restaurant is not valid or doesn't have an _id.");
      }
    } else {
      console.error("No restaurants loaded.");
    }
  }
  
  useEffect(() => {
    console.log("View" + Login.name + " OPENED");
    loading();
    return () => {
      console.log("View" + Login.name + " CLOSED");
    };
  }, []);
  async function loading() {
    console.log("Loading...");
    //verificar se existe um id no local storage se sim verifica se exxiste se sim redireciona para a home
    getAllRestaurants();
    await new Promise((resolve) => setTimeout(resolve, 3000));
    console.log("Loaded!");
    setLoad(false);
  }
  return load ? (
    <Loader />
  ) : (
    <div className="main-base">
      <ToastContainer
        position={window.screen.width > 600 ? "bottom-center" : "top-center"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
      <div className="image-base" />
      <div className="login-base">
        <div className="login-base-box">
          <div className="login-base-box-children-logo">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={"/logo.jpeg"} alt="delivery image" style={{ height: 180, width: 300 }} />
            </div>
          </div>
          <div className="login-base-box-children-text">
            <p className="label-32-bold">Bem vindo</p>
            <p className="label-16-regular">Entre com seu email e senha</p>
          </div>
          <div className="login-base-box-children">
            <select
              className="selectRestaurant"
              value={selectRestaurant}
              placeholder="Selecione um restaurante"
              onChange={(e) => setSelectedRestaurant(e.target.value)}
            >
              {allRestaurants?.map((restaurant, index) => (
                <option
                  className="selectRestaurant"
                  key={index}
                  value={restaurant.name}
                >
                  {restaurant.name}
                </option>
              ))}
            </select>
            <Input placeholder="Email" type="text" setValue={setEmail} />
            <Input
              placeholder="Password"
              type="password"
              setValue={setPassword}
            />

            <Button text="Entrar" onClick={login} />
          </div>
        </div>
      </div>
    </div>
  );
}

import './toBeNamedCard3.css'
export default function ToBeNamedCard3() {
  return (
    <div className="card_tobenamed3">
      <h4>Ratings:</h4>
      <div className="card_info1">
        <p>teste</p>
      </div>
    </div>
  );
}
